import axios from "../api/axios";

export default {
  namespaced: true,
  state: {
    monthEmployees: [],
    monthEmployeeSelected: {},
  },
  actions: {
    async fetchMonthEmployees({ commit }, { year }) {
      try {
        let queryParams = "";

        if (year) {
          queryParams += `year=${year}`;
        }

        const { data } = await axios.get(`/monthEmployee?${queryParams}`);
        commit("setMonthEmployees", data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mutations: {
    setMonthEmployees(state, payload) {
      state.monthEmployees = payload;
    },
    setNewViewToEmployee(state, payload) {
      const employye = state.monthEmployees.find(({ _id }) => _id === payload.viewedItem);

      if (!employye) {
        return;
      }

      const findUserView = employye.viewers.find(({ user }) => user._id === payload.user._id);

      if (findUserView) {
        findUserView.views += 1;
      } else {
        employye.viewers.push(payload);
      }
    },
    setNewClapToEmployee(state, payload) {
      const employye = state.monthEmployees.find(({ _id }) => _id === payload.clapedItem);

      if (!employye) {
        return;
      }

      const indexUserClap = employye.clapers.findIndex(({ user }) => user._id === payload.user._id);

      if (indexUserClap === -1) {
        employye.clapers.push(payload);
      } else {
        employye.clapers.splice(indexUserClap, 1, payload);
      }
    },
    setNewLikeToEmployee(state, payload) {
      const { newLikeWithData, method } = payload;
      const employye = state.monthEmployees.find(({ _id }) => _id === newLikeWithData.likedItem);

      if (!employye) {
        return;
      }

      if (method === "create") {
        const indexUserLike = employye.likers.findIndex(({ user, _id }) => user._id === newLikeWithData.user._id || _id === newLikeWithData._id);

        if (indexUserLike === -1) {
          employye.likers.push(newLikeWithData);
        }
      } else {
        const indexUserLike = employye.likers.findIndex(({ _id }) => _id === newLikeWithData._id);

        if (indexUserLike !== -1) {
          employye.likers.splice(indexUserLike, 1);
        }
      }
    },
  },
  getters: {
    getMonthEmployees(state) {
      return state.monthEmployees || [];
    },
    getEmployeeByYearMonth: (state) => ({ year, month }) => {
      return state.monthEmployees.find((best) => best.year === year && best.month === month);
    },
  },
};

import { isRoleAdmin, isCoorSpecial, isSuperAdmin, isMonitorReports } from "@/js/validation";

export default {
  methods: {
    isRoleAdmin(role) {
      return isRoleAdmin(role);
    },
    isCoorSpecial(user) {
      return isCoorSpecial(user);
    },
    isMonitorReports(user) {
      return isMonitorReports(user);
    },
    isSuperAdmin(user) {
      return isSuperAdmin(user);
    },
  },
};

<script>
  import icons from "@/common/icons.js";
  import { mapActions } from "vuex";
  export default {
    components: {
      Pop: () => import("@/components/alert/PopUpdateSW.vue"),
    },
    methods: {
      ...mapActions("user", { startApp: "startApp" }),
    },
    created: async function() {
      this.$store.dispatch("initEvents");
    },
    computed: {
      app: function() {
        return {
          style: {
            "--app-icon": `url("${icons.src(process.env.VUE_APP_KEY_NAME)}")`,
          },
        };
      },
    },
  };
</script>

<template>
  <div id="app" class="app" v-bind="app">
    <router-view class="filterBlur" />
    <Pop v-if="$store.state.hasUpdateSW" />
  </div>
</template>

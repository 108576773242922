import api from "@/api/api.js";

const createLink = (href) => {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = href;
  return link;
};

const availableLink = (link) => {
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const autoLogin = async (endpointFile, platform, body, $store) => {
  try {
    const dataAuth = await api.get(endpointFile, body);
    const auth = dataAuth.data.auth;
    await api.post("/user/superadmin/createuserhistory", { buttonPressed: platform });

    if (platform !== "desktop" && $store.state.isDesktopApp) {
      parent.postMessage({ message: "autologin", auth }, "*");
    } else if (platform === "desktop") {
      availableLink(createLink(`autologin://${auth}`));
    } else {
      availableLink(createLink(`myspace1a://--autologin=${auth}`));
    }

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const appLogout = (user) => {
  parent.postMessage({ message: "logout", user }, "*");
};

export { autoLogin, appLogout };

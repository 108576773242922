export default {
  namespaced: true,
  state: () => ({
    messagesChat: [],
    chatGroup: {},
    isMinimized: true,
    popupSendModels: false,
    horn: false,
    popUpViewMessagesByUsers: false,
  }),
  getters: {
    unReadMessages(state, _, rootState) {
      return state.messagesChat.filter((msg) => !msg.usersView.some((userView) => userView._id === rootState.user.profile._id));
    },
  },
  actions: {
    async getMessages({ commit }) {
      try {
        const { data } = await this.$axios("/chat");
        if (data?.filterMs?.length) {
          commit("updateMessages", data.filterMs);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async getGroupName({ commit }) {
      try {
        const { data } = await this.$axios("/chatgroup/monitor");
        if (data?.chat) {
          commit("updateChatGroup", data.chat);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async sendMessages(_, payload) {
      try {
        const { errorMsg = null, currentModels = [] } = payload;
        const newMessage = {
          ...payload,
          errorMsg,
          currentModels,
        };
        await this.$axios.post("/chat", newMessage);
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async toggleState({ commit }, payload) {
      commit("toggleStateChange", payload);
    },
  },
  mutations: {
    updateMessages(state, payload) {
      state.messagesChat = payload;
    },
    updateChatGroup(state, payload) {
      state.chatGroup = payload;
    },
    updatePopUpSendModels(state) {
      state.popupSendModels = !state.popupSendModels;
    },
    toggleStateChange(state, payload) {
      state[payload] = !state[payload];
    },
  },
};

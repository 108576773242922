export default {
  validateForm: function(formData, config = {}) {
    const _this = this;
    const fmInputs = document.querySelectorAll(`${formData} input, ${formData} select`);
    const fmOptions = document.querySelectorAll(`${formData} ${config.radiosClass || 'input[type="radio"]'}`);

    [].forEach.call(fmInputs, function(el) {
      if (el.hasAttribute("required")) {
        if (el.hasAttribute("accept")) {
          const type = el.getAttribute("accept");
          if (type.startsWith("image")) {
            _this.validateInput(el, { type: "image" });
          } else if (type.startsWith("video")) {
            _this.validateInput(el, { type: "video" });
          }
        } else {
          _this.validateInput(el);
        }
      }
    });
    [].forEach.call(fmOptions, function(el) {
      if (el.hasAttribute("required")) {
        _this.validateOption(el);
      }
    });
    const dataErrors = document.querySelectorAll(`${formData} .data-error`);

    return dataErrors;
  },
  validateCheckboxList: function(selector) {
    let elements = document.querySelectorAll(selector);
    elements = [...elements];
    const someChecked = elements.some((el) => el.checked);
    return someChecked;
  },
  validateOption: function(el) {
    const _this = this;
    let isSelected = false;
    const myElm = typeof el === "string" ? document.querySelector(el) : typeof el === "object" ? el : el;
    const _inputs = myElm.getElementsByTagName("input") || el;
    [].forEach.call(_inputs, function(_el) {
      if (_el.checked === true) {
        isSelected = true;
      }
    });
    isSelected ? _this.successInput(myElm ? myElm : el) : _this.errorInput(myElm ? myElm : el);
  },
  validEmail: function(email) {
    if (/^[^@ ]+@[^@ ]+\.[^@ \.]{2,}$/.test(email)) {
      return true;
    } else {
      return false;
    }
  },
  validPhone: function(phone) {
    if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)) {
      return true;
    } else {
      return false;
    }
  },
  validNumber: function(value) {
    let numbers = /^[0-9]+$/;
    if (value.match(numbers)) {
      return true;
    } else {
      return false;
    }
  },
  validateInput: function(el, config = { type: "" }) {
    // Objeto que contiene las expresiones regulares para los tipos de archivo que se pueden subir
    const regexTypeInputs = {
      image: /(\.jpg|\.jpeg|\.png|\.gif|\.webp|\.svg)$/i,
      file: /(\.(doc|docx|docm|pdf))$/i,
      video: /(\.(avi|divx|flv|m4v|mkv|mov|mp4|mpeg|mpg|ogm|ogv|ogx|rm|rmvb|smil|webm|wmv|xvid))$/i,
    };

    // Variable que se usa para validar el tipo de archivo
    let typeToValidate = regexTypeInputs[config.type];
    // Se obtiene el elemento del DOM que generó el evento
    const _el = el.srcElement || (typeof el === "object" ? el : el.srcElement);

    // Se obtiene el valor del elemento y se elimina cualquier espacio en blanco al inicio y al final
    const _val = (_el.value || "").trim();

    // Objeto que contiene las funciones de validación para cada tipo de input
    const inputsValidations = {
      email: () => (this.validEmail(_val) ? this.successInput(_el) : this.errorInput(_el, "text")),
      tel: () => (this.validEmail(_val) ? this.successInput(_el) : this.errorInput(_el, "text")),
      number: () => (this.validNumber(_val) ? this.successInput(_el) : this.errorInput(_el)),
      file: () => (_el.files.length && typeToValidate.test(_val) ? this.successInput(_el) : this.errorInput(_el)),
      others: () => (_val ? this.successInput(_el) : this.errorInput(_el, "text")),
    };
    // Tipos de input que se validan con la función "others"
    const othersInputs = ["text", "date", "textarea", "select-one", "tel", "number"];

    // Se ejecuta la función de validación correspondiente para el tipo de input
    if (othersInputs.includes(_el.type)) {
      return inputsValidations.others();
    } else {
      return inputsValidations[_el.type]();
    }
  },

  errorInput: function(el, type = "") {
    const msgError = type == "text" ? "Este campo es obligatorio." : `El formato del archivo es incorrecto`;
    const nextElement = el?.nextElementSibling;
    if (nextElement && nextElement.classList.contains("tooltip")) el.nextElementSibling.remove();
    // el.insertAdjacentHTML("afterend", `<div class="tooltip data-error">${msgError}</div>`);
    el.classList.add("data-error");
    return false;
  },
  successInput: function(el) {
    // el?.nextElementSibling ? el.nextElementSibling.remove() : "";
    const nextElement = el?.nextElementSibling;
    if (nextElement && nextElement.classList.contains("tooltip")) el.nextElementSibling.remove();
    el?.classList.remove("data-error");
    return true;
  },
  isEmptyObj: function(obj) {
    if (obj === [] || obj === null || obj === undefined || obj === {} || obj === "[]") {
      return true;
    }
  },
};

import { model, coordinator, monitor, superadmin, employee } from "@/model/Role.js";

export default {
  menus: [
    {
      id: 1,
      name: "Noticias",
      icon: "newspaper",
      to: "/newsList",
      routes: ["/newsList", "/main"],
      roles: [model.tag, coordinator.tag, monitor.tag, superadmin.tag, employee.tag],
    },
    {
      id: 2,
      name: "Usuarios",
      icon: "users",
      to: "/users",
      routes: ["/users"],
      roles: [coordinator.tag, monitor.tag, superadmin.tag],
    },
    {
      id: 4,
      name: "Concursos",
      icon: "competition",
      iconic: true,
      to: "/competition",
      routes: ["/competition", "/competition/list", "/competition/createCompetition"],
      roles: [superadmin.tag, coordinator.tag, monitor.tag, model.tag, employee.tag],
    },
    {
      id: 5,
      name: "Habitaciones",
      icon: "rooms",
      to: "/availability",
      routes: ["/availability", "/adminReview", "/adminReview/?type=allReview", "/maintenence"],
      roles: [superadmin.tag, coordinator.tag],
    },
    {
      id: 6,
      name: "Mesa de ayuda",
      icon: "helpDesk",
      showButton: true,
      iconic: true,
      to: "/helpdesk",
      routes: ["/helpdesk"],
      roles: [superadmin.tag, monitor.tag, coordinator.tag, model.tag, employee.tag],
    },
    {
      id: 7,
      name: "Bonos",
      icon: "donate",
      to: "/bonds",
      routes: ["/bonds"],
      roles: [superadmin.tag, coordinator.tag, model.tag],
    },
    {
      id: 8,
      name: "Tips monitores",
      icon: "bookIcon",
      iconic: true,
      to: "/guideline",
      routes: ["/guideline"],
      roles: [superadmin.tag, coordinator.tag, monitor.tag],
      key: "guideline",
    },
    {
      id: 9,
      name: "Creación",
      icon: "user-plus",
      to: "/create",
      routes: ["/create"],
      roles: [superadmin.tag],
    },
    {
      id: 10,
      name: "Metas",
      icon: "goals",
      to: "/goals",
      routes: ["/goals"],
      roles: [superadmin.tag],
    },
    {
      id: 11,
      name: "Novedades",
      icon: "novelties",
      iconic: true,
      to: "/novelties",
      routes: ["/novelties", "/novelties?status=pending&date", "/novelties?status=history"],
      roles: [superadmin.tag, coordinator.tag, monitor.tag],
    },
    {
      id: 12,
      name: "Encuestas",
      icon: "poll",
      iconic: true,
      to: "/polls",
      routes: ["/polls"],
      roles: [superadmin.tag],
    },
    {
      id: 13,
      name: "Empleado del mes",
      icon: "monthEmployee",
      iconic: true,
      to: "/monthEmployee",
      routes: ["/monthEmployee"],
      roles: [superadmin.tag],
      state: "New",
    },
  ],
};

import axios from "@/api/axios";
import store from "../store";
import router from "@/router";

export default {
  namespaced: true,
  state: () => ({
    users: [],
    usersSpark: [],
    socketConnected: false,
    usersLoaded: false,
    profiles: null,
  }),
  mutations: {
    setUsers(state, payload) {
      state.users = payload;
    },
    updateUser(state, payload) {
      // If user exist replace them
      const userIdx = state.users.findIndex((user) => user._id === payload._id);
      if (userIdx !== -1) {
        state.users.splice(userIdx, 1, payload);
        return;
      }
      // Else add to users
      state.users.push(payload);
    },
    disconnect(state, payload) {
      const userId = store.state.user.profile._id;
      // If is monitor release models
      if (payload.role === "monitor" || payload.isSpecialFunctions) {
        state.users = state.users.map((user) => {
          if (user.monitorIdTemp === payload._id) {
            user.monitorIdTemp = null;
          }
          return user;
        });
      }

      if (payload.monitorId === userId) {
        // If is official update model state
        const userIdx = state.users.findIndex((user) => user._id === payload._id);
        state.users.splice(userIdx, 1, payload);
        return;
      }
      // Remove user
      const userIdx = state.users.findIndex((user) => user._id === payload._id);
      state.users.splice(userIdx, 1);
    },
    initSocket(state) {
      state.socketConnected = true;
    },
    setUsersSpark(state, payload) {
      state.usersSpark = payload;
    },
  },
  actions: {
    async initUsers({ commit, state }) {
      const { data } = await axios.get("/users/online");
      commit("setUsers", data.onlineUsers);

      if (store.state.user.profile.role === "monitor" || store.state.user.profile.isSpecialFunctions) {
        const { data: officialModels } = await axios.get("/officialModels");
        officialModels.forEach((model) => {
          commit("updateUser", model);
        });
      }
      state.usersLoaded = true;
    },
    async initUsersSpark({ commit, state }) {
      const {
        data: { usersSessions, error },
      } = await axios.get("/usersconnecteds");

      if (error) {
        return false;
      }
      commit("setUsersSpark", usersSessions);
      return true;
    },
  },
  getters: {
    getSocketState: (state) => state.socketConnected,
    getOfficialModels: (state) => {
      let ids = router.app._route.query.id || store.state.user.profile._id;
      return router.app._route.query.id
        ? store.state.users.data.filter((user) => {
            return user.role === "model" && user.monitorId === ids;
          })
        : state.users.filter((user) => {
            return user.role === "model" && user.monitorId === ids;
          });
    },
    getTemporalModels: (state) => {
      let ids = router.app._route.query.id || store.state.user.profile._id;
      return state.users.filter((user) => user.role === "model" && user.monitorId !== ids && user.monitorIdTemp === ids);
    },
    getMonitors: (state) => {
      return state.users.filter((user) => (user.role === "monitor" && user.isOnline) || (user.isSpecialFunctions && user.isOnline));
    },
    getModelsWithMonitor: (state) => {
      return state.users.filter((user) => user.role === "model" && user.isOnline && user.monitorIdTemp);
    },
    modelsAssigned(state, _, rootState) {
      const getModelsWithMonitor = state.users.filter(
        (user) => user.role === "model" && user.isOnline && user.monitorIdTemp && user.monitorIdTemp === rootState.user.profile._id
      );
      const response = getModelsWithMonitor.map((model) => ({
        _id: model._id,
        artisticName: model.artisticName,
        roomTemp: model?.roomTemp?.roomName || model?.room || "",
        picture: model.picture,
        temporalMonitor: { id: "", user: "", picture: "" },
      }));
      return response;
    },
    getModels: (state) => {
      return state.users.filter((user) => user.role === "model" && user.isOnline);
    },
    getModelsInRoom: (state) => {
      return state.users.filter((user) => user.role === "model" && user.roomTemp?.roomName);
    },
    monitorAssigned: (state) => (id) => {
      const ids = id || store.state.user.profile._id;
      const profile = state.users.find((user) => user._id === ids);
      if (profile) {
        return state.users.find((user) => user._id === profile.monitorIdTemp);
      }
      return false;
    },
    getUserInRealTime: (state) => {
      let ids = router.app._route.query.id || store.state.user.profile._id;
      return state.users.find((user) => user._id === ids);
    },
    getUserById: (state) => (id) => {
      return state.users.find((user) => user._id === id);
    },
    getOnlineUsers: (state) => {
      const mainRole = store.state.user.profile.role;
      const usersMap = {};
      const setUsersToMap = (acc, users) => {
        users.forEach((user) => {
          const userFormat = user?.user
            ?.replace(/\s/g, "")
            ?.trim()
            ?.toLowerCase();

          if (user.isOnline) {
            acc[userFormat] = user;
          }
        });
      };

      setUsersToMap(usersMap, state.usersSpark);
      setUsersToMap(usersMap, state.users);

      const usersFiltered = Object.values(usersMap);
      const cb = (user) => ["monitor", "model"].includes(user.role) || user.isSpecialFunctions;

      return mainRole === "superadmin" ? usersFiltered : usersFiltered.filter(cb);

    },
    getUserMapIndex(state) {
      const usersOnlineMap = {};
      state.users.forEach((user) => {
        usersOnlineMap[user._id.toString()] = user;
      });

      return usersOnlineMap;
    },
  },
};

import { createRoute } from "./createRouter";
import guards from "./guards";

const routes = [
  createRoute("/newsList", "newsList", () => import("../views/main/News/news.vue"), { alias: "/main" }),
  createRoute("/account", "account", () => import("../views/main/Account.vue")),
  createRoute("/bonds", "bonds", () => import("../views/main/Bonds.vue"), {
    meta: { rules: { roles: ["model", "coordinator", "superadmin"] } },
    beforeEnter: (...params) => guards.officesDenied(...params, ["Satelite"]),
  }),
  createRoute("/guideline", "guideline", () => import("../views/main/GuideLine.vue"), { meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } } }),
  createRoute("/operation", "", () => import("../views/main/operationNow/OperationNow.vue"), {
    meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } },
    children: [
      createRoute("/", "assignments", () => import("../views/main/operationNow/ModelAssignment.vue"), { meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } } }),
      createRoute("/occupation", "occupation", () => import("../views/main/operationNow/RoomOccupation.vue"), { meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } } }),
      createRoute("/connected", "connected", () => import("../views/main/users/UsersConnected.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
      createRoute("/statistics", "statistics", () => import("../views/main/operationNow/StatisticsOccupation.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
    ],
  }),
  createRoute("/create", "create", () => import("@/views/main/Create/Create.vue"), {
    meta: { rules: { roles: ["superadmin"] } },
    children: [createRoute("", "location", () => import("@/views/main/Create/locationsForm.vue"), { meta: { rules: { roles: ["superadmin"] } } })],
  }),
  createRoute("/rooms", "rooms", () => import("@/views/main/reviewsAdmin/rooms.vue"), {
    meta: { rules: { roles: ["coordinator", "superadmin"] } },
    children: [
      createRoute("/adminReview/", "adminReview", () => import("@/views/main/reviewsAdmin/AdminReview.vue"), { meta: { rules: { roles: ["coordinator", "superadmin"] } } }),
      createRoute("/maintenence", "maintenence", () => import("@/views/main/reviewsAdmin/maintenence.vue"), { meta: { rules: { roles: ["coordinator", "superadmin"] } } }),
      createRoute("/availability", "availability", () => import("@/views/main/reviewsAdmin/availability.vue"), { meta: { rules: { roles: ["coordinator", "superadmin"] } } }),
    ],
  }),
  createRoute("/goals", "goals", () => import("../components/goals/Goals.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
  createRoute("/formNews", "formNews", () => import("@/views/main/News/formNews.vue"), { meta: { rules: { roles: ["coordinator", "superadmin"] } } }),
  createRoute("/users", "", () => import("../views/main/users/UsersView.vue"), {
    meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } },
    children: [
      createRoute("/users/:id", "userSelected", () => import("@/views/main/users/UserSelected.vue"), {
        meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } },
        children: [
          createRoute("/users/:id", "generalInfo", () => import("../views/main/users/GeneralInfoUser.vue"), { meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } } }),
          createRoute("/users/:id/applications", "applications", () => import("../views/main/users/UserApplications.vue"), { meta: { rules: { roles: ["coordinator", "superadmin"] } } }),
        ],
      }),
    ],
  }),
  createRoute("/notifications", "notifications", () => import("../views/Notifications.vue"), { meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } } }),
  createRoute("/novelties", "novelties", () => import("../components/novelties/ControllerNovelties.vue"), {
    meta: { rules: { roles: ["coordinator", "superadmin"] } },
    children: [createRoute("/", "noveltiesAdmin", () => import("../components/novelties/AdminNovelties.vue"), { meta: { rules: { roles: ["monitor", "coordinator", "superadmin"] } } })],
  }),
  createRoute("/helpdesk", "helpdesk", () => import("@/components/helpdesk/viewHelpDesk.vue"), {
    children: [
      createRoute("/", "helpdesk", () => import("@/components/helpdesk/mainHelpDesk.vue")),
      createRoute("/helpdesk/createcase", "createcase", () => import("@/components/helpdesk/createcase.vue")),
      createRoute("/helpdesk/user", "user", () => import("@/components/helpdesk/userViewHelpDesk.vue")),
      createRoute("/helpdesk/resume", "resume", () => import("@/components/helpdesk/resumeCases.vue")),
      createRoute("/helpdesk/detailsCase", "detailsCase", () => import("@/components/helpdesk/detailsCase.vue")),
    ],
  }),
  createRoute("/polls", "polls", () => import("@/components/poll/Polls.vue"), {
    meta: { rules: { roles: ["superadmin"] } },
    children: [
      createRoute("/polls/createPoll", "createPoll", () => import("@/components/poll/ManagePoll.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
      createRoute("/polls/editPoll/:id", "editPoll", () => import("@/components/poll/ManagePoll.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
    ],
  }),
  createRoute("/competition", "competition", () => import("@/components/Competitions/main.vue"), {
    children: [
      createRoute("list", "ListCompetition", () => import("@/components/Competitions/ListCompetition.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
      createRoute("createCompetition", "CreateCompetition", () => import("@/components/Competitions/ManageCompetitions.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
      createRoute("editCompetition/:id", "EditCompetition", () => import("@/components/Competitions/ManageCompetitions.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
      createRoute("", "competition", () => import("@/components/Competitions/RankingView.vue"),{
        children: [
          createRoute(":year", "YearCompetition", () => import("@/components/Competitions/CompetitionByYear.vue"), {
            children: [
              createRoute(":id", "CompetitionSelected", () => import("@/components/Competitions/CompetitionRanking.vue")),
            ]
          }),
        ]
      }),
    ],
  }),
  createRoute("/monthEmployee", "monthEmployee", () => import("@/views/main/MonthEmployee/MonthEmployee.vue"), { meta: { rules: { roles: ["superadmin"] } } }),
  createRoute("/revisionhabitaciones", "reviews", () => import("@/views/review/review.vue"), { meta: { rules: { roles: [] } } }),
];

export default routes;

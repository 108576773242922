<script>
  export default {
    props: ["app", "isClick"],
  };
</script>

<template>
  <div class="myapp" @click="() => isClick(app)">
    <div class="myapp__icon">
      <img class="myapp__img" :src="app.icon" :alt="app.name" />
    </div>
    <p class="myapp__label" ellipsis>{{ app.name }}</p>
  </div>
</template>

<style lang="scss">
  .myapp {
    @include Flex(column);
    gap: 8px;
    color: inherit;
    cursor: pointer;
    &:hover {
      transition: 0.3s ease-in-out;
      .myapp__img {
        transform: scale(1.1);
      }
      .myapp__label {
        color: $primary-color;
      }
    }
    &__label {
      font-family: $newFont_semiBold;
      text-transform: capitalize;
      transition: 0.3s ease-in-out;
    }
    &__img {
      height: 108px;
      width: 97px;
      transition: transform 0.3s ease-in-out;
    }
    @media screen and (min-width: 768px) {
      &__img {
        width: 108px;
      }
    }
  }
</style>

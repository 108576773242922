import axios from "axios";
export default {
  namespaced: true,
  state: {
    category: null,
    newCategory: null,
  },
  mutations: {
    setCategory(state, payload) {
      state.category = payload;
    },
    setNewCategory(state, payload) {
      state.newCategory = payload;
    },
  },
  actions: {
    async getCategories({ commit }) {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API_IP}newsCategories`);
        commit("setCategory", data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async createNewCategory({ commit }, newCategory) {
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_API_IP}newsCategories`, { newCategory });
        commit("setNewCategory", data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};

import { methods } from "./methods";
import { components } from "./components";
export const globals = {
  props: ["autoclose"],
  components: components,
  methods: methods,
  computed: {
    $lockapp() {
      return this.$store.getters.lockapp;
    },
    $loading() {
      return this.$store.getters.loading;
    },
    $locale() {
      return this.$store.getters.locale;
    },
    $user() {
      return this.$store.state.user.user;
    },
    $profile() {
      return this.$store.state.user.profile;
    },
    $payload() {
      return this.$store.getters.payload;
    },
    $creator() {
      return this.$route.params.creator?.replace(/^@/, "");
    },
    $creatorFeed() {
      return this.$store.state.creatorFeed;
    },
    $lang() {
      return this.$store.state.lang.current;
    },
    $screen() {
      return this.$store.getters.screen;
    },
    $visible() {
      return this.$store.getters.visible;
    },
    $updates() {
      return this.$store.getters.updates;
    },
    $search() {
      return this.$store.getters.search;
    },
    $scrollKey() {
      return this.$store.state.scrollKey;
    },
    $isCreator() {
      return this.$user && this.$user["roles"]?.includes("creator");
    },
    $isAdmin() {
      return this.$user && this.$user["roles"]?.includes("super");
    },
    $isUser() {
      return this.$user && !this.$user["roles"].includes("super") && !this.$user["roles"].includes("creator");
    },
    $isTablet: function() {
      return this.$screen.width <= 768;
    },
    $isMobile: function() {
      return this.$screen.width <= 576;
    },
    $isPath: function() {
      return function(path) {
        return path === this.$route.path;
      };
    },
    $style: function() {
      const serialize = (obj) => {
        let style = "";
        if (!obj) return style;
        Object.keys(obj).forEach((key) => {
          style += key + ":" + obj[key] + ";";
        });
        return style;
      };

      return function(object) {
        return serialize(object);
      };
    },
    $isUserVerified: function() {
      return this.$user?.verified;
    },
    $isVerified: function() {
      return this.$user?.verified;
    },
    $notifications: function() {
      return this.$store.getters?.profile?.notifications;
    },
    $isView: function() {
      return function(view) {
        return this.$route.query.view === view;
      };
    },
  },
  mounted: function() {
    if (this.autoclose) {
      setTimeout(() => {
        this.$emit("autoclosed");
      }, +this.autoclose);
    }
  },
};

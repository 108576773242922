if (process.env.VUE_APP_MODE !== "local") {
  document.addEventListener("contextmenu", (event) => event.preventDefault());
  document.addEventListener(
    "keydown",
    (e) => {
      if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
        e.preventDefault();
      }
    },
    false
  );
}

window.blockKeyboard = function(e) {
  const keyCode = e.keyCode ? e.keyCode : e.which;
  if (keyCode) {
    e.preventDefault();
  }
  if (keyCode == 44) {
    var inpFld = document.createElement("input");
    inpFld.setAttribute("value", ".");
    inpFld.setAttribute("width", "0");
    inpFld.style.height = "0px";
    inpFld.style.width = "0px";
    inpFld.style.border = "0px";
    document.body.appendChild(inpFld);
    inpFld.select();
    document.execCommand("copy");
    inpFld.remove(inpFld);
  }
};

export default {
  namespaced: true,
  state: () => ({
    politics: [],
    roles: [],
  }),
  actions: {
    async getPolitics({ commit }, app) {
      try {
        const { data } = await this.$axios(`/politics?idApp=${app}`);
        if (data?.politics?.length) {
          commit("setPolitics", data.politics);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async getRoles({ commit }) {
      try {
        const { data } = await this.$axios("/role");
        if (data?.roles?.length) {
          commit("setRoles", data.roles);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
  },
  mutations: {
    setPolitics(state, payload) {
      state.politics = payload;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
  },
  getters: {
    listPolitics(state) {
      return state.politics;
    },
    listRoles(state) {
      return state.roles;
    },
  },
};

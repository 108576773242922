import "vue-select/dist/vue-select.css";

export const components = {
  Modal: () => import("../../components/widgets/Modal.vue"),
  ModalItem: () => import("../../components/widgets/ModalItem.vue"),
  Collapse: () => import("../../components/widgets/Collapse.vue"),
  TextareaRich: () => import("../../components/widgets/TextareaRich.vue"),
  Confirm: () => import("../../components/widgets/Confirm.vue"),
  LoadMore: () => import("../../components/widgets/LoadMore.vue"),
  UserAvatar: () => import("../../components/widgets/UserAvatar.vue"),
  BubbleNumber: () => import("../../components/widgets/BubbleNumber.vue"),
  MessageBubble: () => import("../../components/widgets/MessageBubble.vue"),
  Tabs: () => import("../../components/widgets/Tabs.vue"),
  TitleBar: () => import("../../components/widgets/TitleBar.vue"),
  Recycler: () => import("../../components/widgets/Recycler.vue"),
  ListItem: () => import("../../components/widgets/ListItem.vue"),
  VideoPlayer: () => import("../../components/widgets/VideoPlayer.vue"),
  ImageVisor: () => import("../../components/widgets/ImageVisor.vue"),
  Spacer: () => import("../../components/widgets/Spacer.vue"),
  ErrorCard: () => import("../../components/widgets/ErrorCard.vue"),
  Emojis: () => import("../../components/widgets/Emojis.vue"),
  ToggleSwitch: () => import("../../components/widgets/ToggleSwitch.vue"),
  VSelect: () => import("vue-select"),
};

export default {
  namespaced: true,
  state: () => ({
    idCase: "",
    caseMessages: [],
    hasMore: false,
    page: 0,
  }),
  actions: {
    async getMessages({ state }, { id, isRead }) {
      try {
        const { data } = await this.$axios.get(`/helpdesk/messages?id=${id}&page=${state.page}&isRead=${isRead}`);
        if (data.error) return;
        state.idCase = id;
        state.hasMore = data.hasMore;
        if (state.page === 0) state.caseMessages = data.data;
        else state.caseMessages = [].concat(state.caseMessages, data.data);
      } catch (e) {
        this.$alerts.dataSendError({ message: error });
      }
    },
    setNewMessage({ state }, payload) {
      if (!payload) return;
      if (payload.idRelation === state.idCase) state.caseMessages.unshift(payload);
    },

    resetMessages({ state }) {
      state.hasMore = false;
      state.page = 0;
      state.caseMessages.length = 0;
      state.idCase = "";
    },
  },
};

import store from "../store/index.js";

const guards = {};

guards.verifyRequireSession = (to, from, next) => {
  const requiresSession = to.matched.some((record) => record?.meta?.requireAuth);
  const isLoggedIn = getIsLoggedIn();
  const isLoggedInMFA = store.getters["user/isAuthenticated"];

  if (requiresSession && !(isLoggedIn || isLoggedInMFA)) {
    return execNext(next, { name: "login" });
  }

  if (requiresSession && isLoggedIn) {
    store.dispatch("user/fetchProfile");
  }
};
guards.verifyRequireIp = (to, from, next) => {
  const requiresIp = to.matched.some((record) => record?.meta?.requireIp);
  const isIpAllowed = getIpIsAllowed();

  if (requiresIp && !isIpAllowed) {
    return execNext(next, { name: "apps" });
  }
};
guards.verifyIsLoggedIn = (to, from, next) => {
  const requiresSession = to.matched.some((record) => record?.meta?.requireAuth);
  const isLoggedIn = getIsLoggedIn();
  const is2Auth = store.getters["user/getIs2FALogged"];

  if (!requiresSession && isLoggedIn && is2Auth) {
    return execNext(next, { name: "apps" });
  }
};
guards.protectMainRoutes = (to, from, next) => {
  const requiresSession = to.matched.some((record) => record?.meta?.requireAuth);

  if (!to?.meta?.rules || !requiresSession) {
    return;
  }

  if (verifyRequireRole(to)) return;

  return execNext(next, { name: "newsList" });
};
guards.officesDenied = (to, from, next, offices) => {
  if (!offices || !offices.length) {
    return next();
  }

  const user = store.getters["user/getProfile"];
  const isOfficeDenied = offices.some((office) => office === user.office);

  if (isOfficeDenied) {
    return execNext(next, { name: "newsList" });
  }

  next();
};
guards.if2Authentication = (to, from, next) => {
  const isAuth = store.getters["user/isAuthenticated"];
  const isRequired2FA = store.getters["user/getIsRequired2FA"];
  const is2Auth = store.getters["user/getIs2FALogged"];

  if (!isAuth) {
    next("/login");
    return;
  }
  if (isAuth && isRequired2FA && !is2Auth) {
    next("/2FA");
    return;
  }

  next();
};

const execNext = (next, go) => {
  next(go);
  return true;
};
const getIsLoggedIn = () => !!localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
const getIpIsAllowed = () => !!localStorage.getItem("ipAllowed");
const verifyRequireRole = (to) => {
  if (!to.meta.rules?.roles?.length) {
    return;
  }

  const user = store.getters["user/getProfile"];

  return to.meta.rules.roles.some((roleAvailable) => roleAvailable === user.role);
};

export default guards;

const apps = require("@/common/apps.js");

const dictionary = {
  ...apps,
  documentType: {
    idcard: "Cédula de ciudadanía",
    idforeign: "Cédula de extranjería",
    passport: "Pasaporte",
    cardForeign: "Tarjeta de extranjería",
    pep: "PEP",
    ppt: "PPT",
  },
  statisticsColors: {
    private: "#EA4335",
    vip_show: "#80FF01",
    scheduled_show: "#3401FF",
    two_way_audio: "#BD01FF",
    cam2cam: "#FF6D01",
    video_voice_call: "#FFF501",
    surprise: "#34A853",
    my_content: "#AE1E1E",
    my_story: "#01FFA4",
    fanclub: "#01D1FF",
    message: "#FBBC04",
    vibratoy: "#F9C1F6",
    other: "#212529",
    interactive_toy: "#4285F4",
  },
  goalTypes: {
    hours: "Horas",
    days: "Tiempo",
    dollars: "Dólares",
    quantity: "Cantidad",
  },
  TurnOfTransmición: {
    empty: "---",
    morning: "06:00 AM - 02:00 PM",
    late: "02:00 PM - 10:00 PM",
    night: "10:00 PM - 06:00 AM",
  },
  workShifts: {
    morning: "06:00 AM - 02:00 PM",
    late: "02:00 PM - 10:00 PM",
    night: "10:00 PM - 06:00 AM",
  },
  reviewData: {
    turnOfTransmition: "Turno de Transmisión",
    userNameModel: "Modelo a revisar",
    city: "Ciudad",
    sede: "Sede",
    room: "Habitación",
    userNameMonitor: "Monitor",
    observation: "Observaciones",
  },
  review: {
    mirror: "Espejo limpio y sin rupturas",
    bed: "Cama tendida, aseada y organizada",
    floor: "Piso barrido y trapeado",
    shower: "Ducha en buen estado",
    nightOwl: "Nocheros/Mesitas de noche en buen estado",
    tv: "TV funcional, sin manchas",
    cpu: "CPU limpia y sin elementos sobre ella",
    accessoriesPc: "Puertos de CPU y cableado en general en buen estado",
    cabling: "Teclado y Mouse operativos, dongle propio conectado y funcional",
    softbotx: "Softbox de luces estable encendido y bombillos completos y funcionales",
    airConditioner: "Aire acondicionado en buen estado",
    functionalAir: "Aire acondicionado apagado",
    tripod: "Trípode de cámara, limpio y funcional",
    webcam: "Cámara web 4K, funcional sin muestras de rayones ni humedad",
  },
  emojis: {
    frown: "😢",
    smile: "😊",
    neutral: "😐",
    smile2: "😁",
    wink: "😉",
    thumbsUp: "👍",
    thumbsDown: "👎",
    kiss: "😘",
    laughing: "😆",
    confused: "😕",
    scream: "😱",
    scream2: "😲",
    scream3: "😳",
    scream4: "😵",
    scream5: "😡",
    scream6: "😠",
    scream7: "😤",
    scream8: "😭",
    scream9: "😨",
    scream10: "😩",
    scream11: "😫",
    scream12: "😪",
    scream13: "😥",
    scream14: "😢",
    scream15: "😭",
    cool: "😎",
    cool2: "😏",
    heart: "💖",
    person: "🤦‍♂️",
    person2: "🤦‍♀️",
    emoji1: "🥳",
    emoji2: "🙀",
    emoji3: "😸",
    emoji4: "🔥",
    emoji5: "🥂",
    emoji6: "✈️",
    emoji7: "😂",
    emoji8: "🤣",
    emoji9: "🚀",
    emoji10: "🤯",
    emoji11: "☕",
    emoji12: "🤑",
    emoji13: "😝",
    emoji14: "😛",
    emoji15: "😍",
    emoji16: "✅",
    emoji17: "🏆",
    emoji18: "👊🏻",
    emoji19: "🙏🏻",
    emoji20: "👑",
    emoji21: "💪",
    emoji21: "🙉",
    emoji38: "🙈",
    emoji23: "🌎",
    emoji24: "☀️",
    emoji25: "☃️",
    emoji26: "🍾",
    emoji27: "🥇",
    emoji28: "🎬",
    emoji29: "☎️",
    emoji30: "🎁",
    emoji31: "📈",
    emoji32: "📊",
    emoji33: "💔",
    emoji34: "🔝",
    emoji35: "🔜",
    emoji36: "🔚",
    emoji37: "📣",
  },
  workShifts_es_auto: {
    morning: "Mañana",
    late: "Tarde",
    night: "Noche",
  },
  cities: {
    Medellín: "medellin",
    Bogotá: "bogota",
    Cúcuta: "cucuta",
    Pereira: "pereira",
  },
  roles_es: {
    empleado: "employee",
    superadmin: "super",
    coordinador: "coord",
    monitor: "monitor",
    modelo: "model",
  },
  roles: {
    employee: "employee",
    superadmin: "super",
    coordinator: "coord",
    monitor: "monitor",
    model: "model",
  },
  rolesInver: {
    employee: "employee",
    super: "superadmin",
    coord: "coordinator",
    monitor: "monitor",
    model: "model",
  },
  roles_es_auto: {
    employee: "empleado",
    superadmin: "superadmin",
    coordinator: "coordinador",
    monitor: "monitor",
    model: "modelo",
  },
  groupsSpark: {
    Modelos: ["modeloscuc", "modelosper", "modelosmed", "modelosbog"],
    Monitores: ["04. Monitores"],
    Administración: ["01. Administración"],
    Contabilidad: ["05. Contabilidad"],
    Diseño: ["02. Diseño"],
    Infraestructura: ["03. Infraestructura"],
    Desarrollo: ["06. Desarrollo Software"],
  },
};

module.exports = { dictionary };

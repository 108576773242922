import axios from "@/api/axios";

export default {
  namespaced: true,
  state: () => ({
    news: [],
    error: "",
    new: {},
    totalNews: 0,
    hasMore: false,
    newsMenu: null,
    NewsVisor: null,
    NewsCommentsVisor: null,
    NewsLikesVisor: null,
    NewsReadersVisor: null,
    NewsSearch: null,
    featured: {
      news: [],
      page: 0,
      total: 0,
    },
    search: {
      q: "",
      page: 0,
      news: [],
      total: 0,
    },
    others: {
      news: [],
      page: 0,
      total: 0,
    },
  }),
  mutations: {
    closeComments(state, data) {
      state.news = state.news.map((item) => {
        if (item._id === data.id) {
          item.newResponse = 0;
        }
        return item;
      });
    },
    setError(state, data) {
      state.error = data;
    },
    setNews(state, data) {
      let cont = 0;
      let condition = true;
      while (condition) {
        if (!data[cont]?.forUser[cont] && !data[cont]?.featured) {
          data[cont] = { ...data[cont], isNew: true };
          condition = false;
        } else {
          if (data.length == cont) condition = false;
          cont++;
        }
      }
      state.news = data;
    },
    setNew(state, data) {
      state.new = data;
      state.news.push(data);
    },
    pushNews(state, data) {
      state.news.push(...data);
    },
    setTotal(state, data) {
      state.totalNews = data;
    },
    setHasMore(state, data) {
      state.hasMore = data;
    },
    updateNew(state, data) {
      state.news = state.news.map((item) => {
        if (item._id === data._id) {
          item[data.key] = data.value;
        }
        return item;
      });
    },
    updateLikes(state, data) {
      let [self, isMyLike] = [this, false];
      const { like, userId } = data;

      // update like news
      state.news = state.news.map((news) => {
        if (news._id === like.like.newsId) {
          if (like.status === "like") {
            news.likes.total++;
            news.likes.likesUser.unshift(like.like);
            if (like.like.userId._id === userId) {
              news.likes.isMyLike = true;
              isMyLike = true;
            }
          }
          if (like.status === "unlike") {
            const i = news.likes.likesUser.findIndex((_like) => _like.userId._id === like.like.userId);
            news.likes.total--;

            if (like.like.userId === userId) {
              news.likes.isMyLike = false;
            }

            if (i !== -1) {
              news.likes.likesUser.splice(i, 1);
            }
          }
        }
        return news;
      });

      // update like others news
      state.others.news = state.others.news.map((news) => {
        if (news._id === like.like.newsId) {
          if (like.status === "like") {
            news.likes.total++;
            news.likes.likesUser.unshift(like.like);
            if (like.like.userId._id === userId) {
              news.likes.isMyLike = true;
              isMyLike = true;
            }
          }
          if (like.status === "unlike") {
            const i = news.likes.likesUser.findIndex((_like) => _like.userId._id === like.like.userId);
            news.likes.total--;

            if (like.like.userId === userId) {
              news.likes.isMyLike = false;
            }

            if (i !== -1) {
              news.likes.likesUser.splice(i, 1);
            }
          }
        }
        return news;
      });
    },
    handleComments(state, { newId, toResponse, userId }) {
      state.news = state.news.map((newItem) => {
        if (newItem._id === newId) {
          if (toResponse && toResponse === userId) newItem.newResponse++;
          newItem.comments++;
        }
        return newItem;
      });
    },
  },
  actions: {
    async fetchNews({ commit }) {
      commit("setError", "");
      const res = await axios.get(`/news`);
      if (res.data.error) {
        commit("setError", res.data.error);
      } else {
        commit("setNews", res.data.news);
        commit("setHasMore", res.data.hasMore);
      }
    },

    async createNew({ commit }, payload) {
      commit("setError", "");
      const res = await axios.post(`/news`, payload);
      if (res.data.error) {
        commit("setError", res.data.error);
      } else {
        commit("setNew", res.data);
      }
    },
    async updateNew({ commit }, payload) {
      commit("setError", "");
      const res = await axios.put(`news/${payload.id}`, payload.data);
      if (res.data.error) {
        commit("setError", res.data.error);
      } else {
        commit("setNew", res.data);
      }
    },
    async deleteNew({ commit }, id) {
      commit("setError", "");
      const res = await axios.post(`/news/enable`, { id });
      if (res.data.error) {
        commit("setError", res.data.error);
      } else {
        commit("setNew", res.data);
      }
    },
    async fetchLastNews({ state }) {
      try {
        if (state.news.length > 0) {
          return state.news[0];
        } else {
          const { data } = await axios.get(`/news/last`);
          return data;
        }
      } catch (error) {
        console.error("Error fetching last news:", error);
        throw error;
      }
    },
  },
  getters: {
    getError: (state) => state.error,
    getNews: (state) => state.news,
    getNew: (state) => state.new,
    getTotal: (state) => state.totalNews,
    getHasMore: (state) => state.hasMore,
  },
};

const changeCity = (user) => {
  changeOffice(user);
  user.office = null;
};

const changeOffice = (user) => {
  user.room = null;
};

const formatNumber = (ref, info, ev) => {
  const data = ev.value;
  return validateNumber(ref, data, info);
};

const formatString = (ref, info, ev) => {
  const data = ev.value;
  return validateString(ref, data, info);
};
const formatEmail = (ref, _, ev) => {
  const data = ev.value;
  return validateEmail(ref, data);
};

const formatAlphaNumeric = (ref, info, ev) => {
  const data = ev.value;
  return validateAlphaNumeric(ref, data, info);
};

const formatSelection = (ref, info, ev) => {
  const data = ev.value;
  return validateSelect(ref, data, info);
};

const validateNumber = (ref, data, info) => {
  const reference = ref;
  const re = /^[0-9]*$/;
  if (data === "") {
    reference.innerText = `Ingrese el ${info}`;
    return false;
  }
  if (!re.test(data)) {
    reference.innerText = `${info} inválido`;
    return false;
  }
  reference.innerText = "";
  return true;
};

const validateString = (ref, data, info) => {
  const reference = ref;
  const re = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
  if (data === "") {
    reference.innerText = `Ingrese el ${info}`;
    return false;
  }
  if (!re.test(data)) {
    reference.innerText = `${info} inválido`;
    return false;
  }
  reference.innerText = "";
  return true;
};
const validateEmail = (ref, data) => {
  const reference = ref;
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (data === "") {
    reference.innerText = "Ingrese el Correo";
    return false;
  }
  if (!re.test(data)) {
    reference.innerText = "Correo inválido";
    return false;
  }
  reference.innerText = "";
  return true;
};

const validateAlphaNumeric = (ref, data, info) => {
  const reference = ref;
  if (data.length < 4) {
    reference.innerText = `${info} demasiado corta`;
    return false;
  }
  if (data === "" || data === "NA") {
    reference.innerText = `Ingrese ${info}`;
    return false;
  }
  reference.innerText = "";
  return true;
};

const validateSelect = (ref, data, info) => {
  const reference = ref;
  if (data === "" || data === null) {
    reference.innerText = `Ingrese ${info}`;
    return false;
  }
  reference.innerText = "";
  return true;
};

const isRoleEmployee = (role) => role === "employee";
const isRoleMonitor = (role) => role === "monitor";
const isRoleModel = (role) => role === "model";
const isRoleCoord = (role) => role === "coordinator";
const isRoleAdmin = (role) => role === "superadmin" || role === "coordinator";
const isCoorSpecial = (user) => user.role === "coordinator" && user.isSpecialFunctions;
const isMonitorReports = (user) => user.role === "monitor" && user.isReports;
const isSuperAdmin = (user) => user.role === "superadmin";

export {
  changeCity,
  changeOffice,
  isRoleAdmin,
  formatNumber,
  formatString,
  formatEmail,
  formatAlphaNumeric,
  formatSelection,
  isRoleMonitor,
  isRoleModel,
  isRoleCoord,
  isCoorSpecial,
  isMonitorReports,
  isSuperAdmin,
  isRoleEmployee,
};

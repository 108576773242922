import alerts from "@/js/swalAlerts";
export default {
  computed: {
    isAdminNews: function() {
      const allows = ["superadmin", "coordinator", "super", "coord"];
      let allow = false;
      let roles = this.$profile ? this.$profile.roles || [] : [];

      [].forEach.call(roles, (role) => {
        if (allows.indexOf(role) !== -1) {
          allow = true;
        }
      });

      return allow;
    },
    isNewsArchive: function() {
      return function(news) {
        if (typeof news?.archive === "undefined") return true;
        return news.archive;
      };
    },
    isNewsPrivate: function() {
      return function({ forUser }) {
        if (forUser && forUser[0]) {
          return true;
        }
      };
    },
  },
  methods: {
    newsMenu: function(news, evt) {
      news.event = evt;
      this.$store.state.news.newsMenu = news;
    },
    viewNews: function(news) {
      this.$store.state.news.NewsVisor = news;
      this.$router.push({ query: { news: news._id } }).catch(() => {});
    },
    viewNewsComments: function(news) {
      this.$store.state.news.NewsCommentsVisor = news;
    },
    viewNewsLikes: function(news) {
      this.$store.state.news.NewsLikesVisor = news;
    },
    viewNewsReaders: function(news) {
      this.$store.state.news.NewsReadersVisor = news;
    },
    NewsSearch: function() {
      this.$store.state.news.NewsSearch = true;
    },
    deleteNew: async function(newNew) {
      const { isConfirmed } = await alerts.dataSendConfirm({
        title: "Eliminar",
        message: "¿Está seguro? Se eliminará la noticia.",
        buttonTextNo: "No",
        buttonTextYes: "Si",
      });
      if (!isConfirmed) {
        return;
      }
      let { thumbnail, documentNews, filesId, videoNews, imageNews } = newNew;
      thumbnail ? (thumbnail = { thumbnail }) : (thumbnail = "");
      documentNews ? (documentNews = { documentNews }) : (documentNews = "");
      filesId ? (filesId = { filesId }) : (filesId = "");
      videoNews ? (videoNews = { videoNews }) : (videoNews = "");
      imageNews ? (imageNews = { imageNews }) : (imageNews = "");
      const data = {
        _id: newNew._id,
        ...thumbnail,
        ...documentNews,
        ...filesId,
        ...videoNews,
        ...imageNews,
      };
      await this.$api.delete(`/news/`, { data });
      await this.$store.dispatch("news/fetchNews");
    },
    onToggle: async function(evt, id) {
      const { isConfirmed } = await this.$alerts.dataSendConfirm({
        title: `${evt ? "Mostar" : "Archivar"} la noticia`,
        message: `Esta noticia ${evt ? "estará visible" : "no estará disponible"} para los usuarios.`,
        buttonTextYes: "Si, estoy seguro",
      });
      if (!isConfirmed) {
        this.toggleKey++;
        return;
      }
      this.$alerts.dataSending();
      const {
        data: { error, success },
      } = await this.$api.post(`/news/enable`, { id, archive: evt });
      this.$alerts.closeSwal();
      if (error) {
        this.$alerts.dataSendError({ message: error });
        this.toggleKey++;
        return;
      }
      this.$store.commit("news/updateNew", { _id: id, key: "archive", value: evt });
      this.$alerts.dataSendSuccess({ message: success });
    },
  },
};

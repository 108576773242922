export const methods = {
  goBack: function() {
    const MyView = this.$refs.MyView;
    if (MyView) {
      MyView.close(() => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  },
  UserMenu: function(status) {
    this.$store.commit("UserMenu", status);
  },
  setQuery: function(myQuery) {
    let currentQuery = this.$route.query;
    let newQuery = { query: { ...currentQuery, ...myQuery } };
    this.$router.push(newQuery).catch(() => {});
    this.$emit("query");
  },
  mkLink: function(path, className, options = {}) {
    let { exact } = options;
    let isPath = this.$global.linkInclude(this.$route, path);

    if (exact) {
      let { fullPath } = this.$route;
      let regx = new RegExp(`${path}$`);
      isPath = regx.test(fullPath);
    }

    return isPath ? className || "router-link-exact-active" : "";
  },
  isPath: function(path) {
    return path === this.$route.path;
  },
  mediaType: function(type) {
    if (!type) return;

    const isObj = this.$global.isObject(type);

    if (isObj) {
      if (type?.name?.includes("img")) return "image";
      if (type?.type?.includes("img")) return "image";
      if (type?.type?.includes("image")) return "image";
      if (type?.type?.includes("video")) return "video";
      type = type.type;
    } else {
      if (type?.includes("image")) return "image";
      if (type?.includes("video")) return "video";
    }

    const isImage = this.$global.isImage(type);
    if (isImage) return "image";

    const isVideo = this.$global.isVideo(type);
    if (isVideo) return "video";

    return "unknown";
  },
  number_format: function(number, decimals) {
    return this.$global.number_format(number, decimals);
  },
  getPercent: function(mount, total) {
    return Math.round((mount / total) * 100);
  },
  getDiscountPercent: function(regularObtain, plusObtain) {
    const percent = ((regularObtain - plusObtain) / plusObtain) * 100;
    return percent.toFixed(0);
  },
  el: function(selector, callback) {
    if (!selector) return;
    const el = document.querySelector(selector);
    if (el) {
      if (callback) return callback(el);
      else return el;
    }
  },
  getTimeLapse: function({ date }) {
    let dateFrom = date;
    let dateTo = new Date(Date.now());

    const { days, hours, minutes, seconds } = this.$global.dateDiff(dateFrom, dateTo);
    const months = (days / 30).toFixed();

    if (!days && !hours && !minutes) {
      return this.$locale["object"]["user-post"]["post-few-seconds-lapse"]?.replaceAll("$seconds", seconds);
    } else if (!days && !hours) {
      return this.$locale["object"]["user-post"]["post-minutes-lapse"]?.replaceAll("$minutes", minutes);
    } else if (!days) {
      return this.$locale["object"]["user-post"]["post-hours-lapse"]?.replaceAll("$hours", hours);
    } else if (days < 30) {
      return this.$locale["object"]["user-post"]["post-days-lapse"]?.replaceAll("$days", days);
    } else {
      return this.$locale["object"]["user-post"]["post-months-lapse"]?.replaceAll("$months", months);
    }
  },
  lowercase: function(str) {
    return str?.toLowerCase();
  },
  uppercase: function(str) {
    return str?.toUpperCase();
  },
  capitalize: function(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  },
  showSuggest: function({ index, each }) {
    const { adminStatus } = this.$route.query;
    const isShow = index >= each ? index % each === 0 : false;

    if (this.$isCreator) {
      return false;
    }

    if (!adminStatus && isShow) {
      return true;
    }
  },
  date_locale: function(date) {
    return this.$global.dateLocaleFormat(date);
  },
  sleep: function(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  normalize: function(str) {
    return this.$global.normalizeString(str);
  },
  randomNumber: function(min = 0, max = 100, opt = {}) {
    if (opt.int) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } else {
      return Math.random() * (max - min) + min;
    }
  },
  words: function(key) {
    return this.$store.state.locale.words[key];
  },
  objectToQuery: function(obj) {
    return this.$global.objectToQuery(obj);
  },
  currentYear: function() {
    return this.$global.currentYear();
  },
  isFalsy: function(value) {
    return this.$global.isFalsy(value);
  },
  convertToType: function(value, type) {
    return this.$global.convertToType(value, type);
  },
  canAccessToModel: function(user, model) {
    return this.$global.canAccessToModel(user, model);
  },
  isSuperadmin: function(user) {
    let isSuper = user.role === "superadmin";

    if (!isSuper) {
      isSuper = user.rolesApp?.autologin?.includes("super");
    }
    return isSuper;
  },
};

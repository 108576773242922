<template>
  <div class="myapps">
    <App v-for="app in userAvailableApps" :key="app.key" :app="app" :isClick="goToOtherApp" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  import App from "@/components/art/app.vue";
  import { getApps, goToApp } from "@/common/app.main.js";

  export default {
    components: { App },
    computed: {
      ...mapGetters("user", {
        getId: "getId",
        getProfile: "getProfile",
      }),
      user() {
        return this.getProfile;
      },
      apps() {
        return getApps();
      },
      userAvailableApps() {
        return this.apps.filter((app) => this.user.apps.includes(app.key));
      },
      numAvailableApps() {
        return this.userAvailableApps.length <= 3 ? this.userAvailableApps.length : 3;
      },
      isLogin() {
        return localStorage.getItem("ipAllowed");
      },
      showApps() {
        return this.userAvailableApps.length <= 4 && window.innerWidth > 700;
      },
    },
    methods: {
      async goToOtherApp(app) {
        const tokenName = process.env.VUE_APP_TOKEN_NAME;
        const appName = process.env.VUE_APP_KEY_NAME;
        const isDesktopApp = this.$store.state.isDesktopApp;

        await goToApp(app, this.user, tokenName, appName, isDesktopApp);
      },
    },
  };
</script>

<style lang="scss">
  .myapps {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    max-width: 360px;
  }
</style>

export default {
  namespaced: true,
  state: () => ({
    policies: [],
  }),
  getters: {
    policiesDb(state) {
      return state.policies;
    },
  },
  actions: {
    async getPolicies({ commit }) {
      try {
        const { data } = await this.$axios("/politics");
        if (data.politics?.length) {
          commit("setPolicies", data.politics);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
  },
  mutations: {
    setPolicies(state, payload) {
      state.policies = payload;
    },
  },
};

<script>
  export default {
    props: ["dataCard"],
    computed: {
      hasAction() {
        return !!this.dataCard?.action;
      },
    },
    methods: {
      execItemAction() {
        if (!this.hasAction) {
          return;
        }
        this.dataCard?.action();
      },
    },
  };
</script>
<template>
  <div :class="['cardHome', hasAction && 'cardHome--action']" @click="execItemAction">
    <div class="cardHome__name">
      <iconic :class="`cardHome__icon cardHome__icon--${dataCard.iconClass}`" :name="dataCard.icon" />
      <p class="cardHome__title">{{ dataCard.title }}</p>
    </div>
    <div class="cardHome__body">
      <p class="cardHome__text">
        {{ dataCard.content }}
      </p>
    </div>
  </div>
</template>
<style lang="scss">
  .cardHome {
    width: 100%;
    max-width: 570px;
    padding: 15px 15px;
    border-radius: 15px;
    box-shadow: 0px 4px 16px 0px #f238471a;
    background: #ffffff;
    &--action {
      cursor: pointer;
    }
    &__name {
      display: flex;
      gap: 10px;
      margin: 0px 0px 5px 0px;
    }
    &__icon {
      &--success {
        color: #20c306;
      }
      &--primary {
        color: $primary-color;
      }
    }
    &__title {
      font-family: $newFont_medium;
      color: #272727;
    }
    &__text {
      font-family: $newFont_regular;
      color: grey;
      font-size: 14px;
    }
  }
</style>

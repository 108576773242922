import axios from "axios";
export default {
  namespaced: true,
  state: {
    getSkip: null,
    newSkip: null,
    skipById: null,
  },
  mutations: {
    setSkip(state, payload) {
      state.getSkip = payload;
    },
    setNewSkip(state, payload) {
      state.newSkip = payload;
    },
    setSkipById(state, payload) {
      state.skipById = payload;
    },
  },
  actions: {
    async getSkip({ commit }) {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API_IP}skipNewsHome`);
        commit("setSkip", data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async createNewSkip({ commit }, newSkip) {
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_API_IP}skipNewsHome`, { newSkip });
        commit("setNewSkip", data);
      } catch (error) {
        console.log(error);
      }
    },
    async getSkipById({ commit }, idUser) {
      try {
        const { data } = await axios.get(`${process.env.VUE_APP_API_IP}skipNewsHome/${idUser}`);
        commit("setSkipById", data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export const model = {
  _id: 1,
  tag: "model",
  img: "img/assets/model.png",
  name: "Modelo",
};

export const monitor = {
  _id: 2,
  tag: "monitor",
  img: "/img/monitor.png",
  name: "Monitor",
};

export const coordinator = {
  _id: 3,
  tag: "coordinator",
  img: "/img/coordinator.png",
  name: "Coordinador",
};

export const superadmin = {
  _id: 4,
  tag: "superadmin",
  img: "/img/superadmin.png",
  name: "Superadmin",
};

export const employee = {
  _id: 5,
  tag: "employee",
  img: "/img/monitor.png",
  name: "Empleado",
};

export default {
  employee,
  model,
  monitor,
  coordinator,
  superadmin,
  roles: [model, monitor, coordinator, superadmin, employee],
};

export default {
  computed: {
    $userData() {
      return this.$store.getters["user/getProfile"];
    },
    $userToken() {
      return this.$store.getters["user/getProfileToken"];
    },
    $usersLoaded() {
      return this.$store.state.connectedUsers.usersLoaded;
    },
    $realTimeUser() {
      return this.$store.getters["connectedUsers/getUserInRealTime"];
    },
    $cities() {
      return this.$store.getters["cities/getData"].map((city) => city.isEnable && city.cityName);
    },
    $socketState() {
      return this.$store.getters["connectedUsers/getSocketState"];
    },
  },
  methods: {
    $isPicture(user) {
      if (user.picture) {
        return `${user.picture}`;
      }
      return undefined;
    },
  },
};

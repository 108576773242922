import Vue from "vue";
import Router from "vue-router";
import authRoutes from "./auth";
import homeRoutes from "./home.js";
import errorRoutes from "./errors";
import devRoutes from "./dev";
import Apps from "@/views/Apps.vue";
import guards from "./guards.js";
import menuList from "../components/widgets/MainMenu.js";
import { createRoute } from "./createRouter";

Vue.use(Router);

const setCurrentMenu = (to) => {
  const { path } = to;
  const splittedRoutes = path.split("/")[1];
  const menu = menuList.menus.find((menu) => menu.routes.includes(`/${splittedRoutes}`));

  if (menu) {
    to.meta.menuActive = menu;
  }
};

const routes = [
  createRoute("/", "authcomponent", () => import("../components/widgets/AuthComponent.vue"), {
    children: [
      createRoute("/login", "login", () => import("../components/widgets/LoginComponent.vue"), { alias: "" }),
      createRoute("/2FA", "2FA", () => import("../components/widgets/TwoFactorAuthComponent.vue"), { meta: { requireAuth: true } }),
      createRoute("/restorepass", "restorePassDefault", () => import("../components/widgets/defaultRestorePass.vue")),
      createRoute("/method", "method", () => import("../components/widgets/restorePassMethod.vue")),
      createRoute("/newPassword", "newPassword", () => import("../components/widgets/NewPassword.vue")),
    ],
  }),
  createRoute("/needchangepassword", "", () => import("../components/auth/NeedChangePass.vue")),
  createRoute("/apps", "apps", Apps, { beforeEnter: guards.if2Authentication }),
  createRoute("/dev", "develop", () => import("../components/dev/Dev.vue"), { meta: { requireAuth: true }, children: devRoutes }),
  createRoute("/main", "", () => import("@/views/Main.vue"), { meta: { requireAuth: true, requireIp: true }, children: homeRoutes }),
  ...authRoutes,
  ...errorRoutes,
  { path: "*", redirect: "/" },
];

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (guards.verifyRequireSession(to, from, next)) return;
  if (guards.verifyRequireIp(to, from, next)) return;
  if (guards.protectMainRoutes(to, from, next)) return;
  setCurrentMenu(to);

  next();
});

export default router;

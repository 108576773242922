export default {
  namespaced: true,
  state: {
    comment: [],
  },
  getters: {
    allComments(state) {
      return state.comment;
    },
  },
  mutations: {
    setNewComment(state, payload) {
      state.comment.push(payload);
    },
    setComment(state, payload) {
      state.comment = payload;
    },
  },
  actions: {
    async createNewcomment({ commit }, newComment) {
      try {
        const { data } = await this.$axios.post(`${process.env.VUE_APP_API_IP}comments`, newComment);
        commit("setNewComment", data);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear el comentario");
      }
    },
    async getCommentsByModel({ commit }, _id) {
      try {
        const { data, error } = await this.$axios.get(`${process.env.VUE_APP_API_IP}comments/${_id}`);
        if (error) {
          throw Error(error);
        }
        commit("setComment", data);
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
  },
};

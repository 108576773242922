import api from "./api";

export default entity => {
  return {
    search: query => {
      const queries = Object.keys(query)
        .map(key => `${key}=${query[key]}`)
        .join("&");
      return api.get(`/${entity}?${queries}`);
    },
    fetch: (query = "") => api.get(`/${entity}/${query}`),
    get: id => api.get(`/${entity}/${id}/`),
    update: (id, data) => api.patch(`/${entity}/${id}/`, data),
    create: data => api.post(`/${entity}/`, data),
    delete: id => api.delete(`/${entity}/${id}/`)
  };
};

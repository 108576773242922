import global from "./global";

/***********************************************
 * Funcion que da formato a una fecha
 * recibe una fecha y las opciones del formato.
 * ejp 1999/06/12 13:30
 ***********************************************/
const dateLocaleFormat = (date, options) => {
  let _date = new Date(date);
  const ulang = "es";
  return _date.toLocaleDateString(ulang, options);
};

/***********************************************
 * Funcion que da formato a una fecha
 * ejp 5 de agosto de 2021
 ***********************************************/
const dateLocaleFormatEs = (date) => {
  date = new Date(date);
  const ulang = "es";
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString(ulang, options);
};

/********************************************************
 * funcion que valida la jerarquia de roles,
 * recibe role a comparar y role actual conectado.
 ********************************************************/
const roleRestriction = (roleNotify, roleState) => {
  let restriction = [];
  let status = true;
  switch (roleState) {
    case "model":
      restriction = ["model", "coordinator", "superadmin"];
      break;
    case "monitor":
      restriction = ["coordinator", "superadmin"];
      break;
    case "coordinator":
      restriction = ["superadmin"];
      break;
    case "superadmin":
      status = true;
      break;
    default:
      status = false;
  }
  if (restriction.includes(roleNotify)) {
    status = false;
  }
  return status;
};
const getMessage = (item) => {
  if (item.userAction?.role === "superadmin") return `El superadmin <strong> ${item.userAction.user} </strong>`;
  if (item.userAction?.role === "coordinator") return `El coordinador <strong> ${item.userAction.user} </strong>`;
  if (item.userCoord) return `El coordinador <strong> ${item.userCoord} </strong>`;
  return "El coordinador";
};

/*************************************************
 * funcion que determina el mensaje a mostrar en
 * la notificacion y la vista de notificaciones
 * recibe el objeto de la data a estructurar.
 *************************************************/
const showMessage = (item, user, isLog) => {
  switch (item.typeNotifications) {
    case "seeStats":
      return `<strong>${item.userName}</strong> (${item.isTemp ? "Temporal" : "Oficial"})
      está observando las estadísticas de <strong>${item.modelArtisticName}${item.platform ? "</strong> en <strong>" + item.platform + "</strong>" : ""}`;

    case "Logout":
      return "<strong>" + item.userName + "</strong>" + " cerró sesión.";

    case "Login":
      let msg = `<strong> ${item.userName} </strong> ingresó a la aplicación`;
      if (item.location) {
        if (item.location.city) {
          msg = msg.concat(` desde la ciudad <strong>${item.location.city}</strong>`);
        }
        if (item.location.office) {
          msg = msg.concat(` y sede <strong>${item.location.office}</strong>`);
        }
        if (item.location.room) {
          msg = msg.concat(`, en la habitación <strong>${item.location.room}</strong>`);
        }
      }
      msg = msg.concat(".");
      return msg;

    case "tempAssign":
      return user.role !== "model"
        ? `<strong>${item.modelArtisticName}</strong> tiene asignado a <strong>${item.userName}</strong> temporalmente.`
        : `<strong>${item.modelArtisticName}</strong> fuiste asignada a <strong>${item.userName}</strong> temporalmente.`;

    case "assignViewReportMonitor":
      return `${getMessage(item)} delegó permisos para la creación de reportes al monitor <strong>${item.userName}</strong>`;

    case "ChangeApp":
      return "<strong>" + item.userName + "</strong>" + " salió de la aplicación.";

    case "break":
      return `<strong>${item.modelArtisticName}</strong> tomó un receso de tipo <strong>${item.typeBreak}</strong> el cual duró 
      <strong>${setTime(item.time)}</strong>, monitor que terminó la transmisión: <strong>${item.userName}</strong>`;

    case "Review":
      return `<strong>${item.userName}</strong> revisó la habitación de la modelo <strong>${item.modelArtisticName}</strong>, en
    <strong>${item?.location?.city}</strong>, en la sede <strong>${item?.location?.office}</strong>, en la habitación <strong>${item?.location?.room}</strong>.`;

    case "Streaming":
      let notifyMessage = "";
      if (item.streamingState === "start") {
        notifyMessage = `<strong>${item.modelArtisticName}</strong> inició una transmisión en 
        <strong>${item.app}</strong> monitoreada por <strong>${item.userName}</strong>`;
      }
      if (item.streamingState === "end") {
        notifyMessage = `<strong>${item.modelArtisticName}</strong> finalizó la transmisión desde <strong>${item.location.city}, </strong><strong>
        ${item.location.office}</strong>, <strong>${item.location.room}</strong>  en el turno de la  <strong>${getTurnName(item.turn)}</strong>`;
      }
      return notifyMessage;

    case "officialAssign":
      return user.role !== "model"
        ? `<strong>${item.modelArtisticName}</strong> tiene asignado a <strong>${item.userName}</strong>.`
        : `<strong>${item.modelArtisticName}</strong> fuiste asignada a <strong>${item.userName}</strong>.`;

    case "maintenenceRoom":
      let textmsg = "";
      if (!item.statusMaintenence) {
        textmsg = "no necesita mantenimiento.";
      } else {
        textmsg = "necesita mantenimiento.";
      }
      return `<strong>${item.userName}</strong> en <strong>${item.location.city}</strong> en la sede 
        <strong>${item.location.office}</strong> cambió el estado de la habitación <strong>${item.location.room}</strong>: ${textmsg}`;

    case "Goals":
      let message;
      const { updateAction } = item;
      if (updateAction?.isFromApi) {
        message = `El sistema
        aumentó <strong>${updateAction?.goal?.type === "hours" ? global.parseMinutesToTime(updateAction?.value) : global.formatPrice(updateAction?.value)}</strong> el progreso de la modelo 
        <strong>${item?.modelArtisticName}</strong> en la meta <strong>${updateAction?.goal?.name}</strong>.
        Fecha: <strong>${item.date.substring(0, 10)}</strong>.
        `;
      } else {
        if (item.role === "monitor") {
          message = `El monitor 
          <strong>${item?.userName}</strong> 
          aumentó el progreso de la modelo 
          <strong>${item?.modelArtisticName}</strong> a 
          <strong>${item?.valueUpdated}</strong> en la meta <strong>${item?.goalUpdated}</strong>.
          Fecha: <strong>${item?.declaredDate?.substring(0, 10)}</strong>.`;
        }
        if (item.role === "superadmin" || item.role === "coordinator") {
          message = ` 
          <strong>${item?.userName}</strong> 
          ${updateAction?.action === "increase" ? "aumentó" : "disminuyó"}
          <strong>${global.parseDaysToDate(updateAction?.value)}</strong>
          ${isLog ? `por <strong>${updateAction?.reason}</strong>` : ""}
          de <strong>${updateAction?.goal?.name}</strong> de
          <strong>${item?.modelArtisticName}</strong>`;
        }
      }
      return message;
  }
};

/**********************************************************
 funcion que recibe role de la base de datos 
 devuelve Rol a mostrar al Usuario
 **********************************************************/
const transformRoleName = (role) => {
  let roleName = "";
  switch (role) {
    case "modelo":
      roleName = "Modelos";
      break;
    case "model":
      roleName = "Modelos";
      break;
    case "monitor":
      roleName = "Monitores";
      break;
    case "coordinator":
      roleName = "Coordinadores";
      break;
    case "coordinador":
      roleName = "Coordinadores";
      break;
    case "superadmin":
      roleName = "Superadministradores";
      break;
  }
  return roleName;
};

const setTime = (time) => {
  const localDate = {
    0: "H",
    1: "M",
    2: "S",
  };
  const date = time.split(":");
  const formatDate = [];
  for (let i = 0; i < date.length; i++) {
    formatDate.push(date[i] + localDate[i]);
  }
  return formatDate.join(":");
};

const getTurnName = (turnName) => {
  switch (turnName) {
    case "morning":
      return "Mañana";
    case "late":
      return "Tarde";
    case "night":
      return "Noche";
    default:
      return null;
  }
};

/***********************************************
 * Funcion que limita la frecuencia con la que una
 * funcion que se ejecuta muchas veces para optimizar
 * el rendimiento, un ejemplo de uso es el evento
 * de resize que se ejecuta muchas veces o cuando
 * un usuario escribe en un input y se necesita
 * hacer el llamado a una api cuando el usuario
 * ha terminado de escribir
 ***********************************************/

const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

/*****************************************************
 * `calculateTimeLeft` calcula la diferencia de tiempo entre
 * `initialDate` y `targetDate`, retornando un objeto con
 * días, horas, minutos y segundos restantes.
 *
 * **Parámetros:**
 * - `initialDate`: Fecha inicial.
 * - `targetDate`: Fecha objetivo.
 *
 * **Retorna:**
 * - Objeto con `days`, `hours`, `minutes`, `seconds`.
 * - Incluye `error` si ocurre un problema.
 *******************************************************/

const calculateTimeLeft = (initialDate, targetDate) => {
  try {
    const initial = new Date(initialDate);
    const target = new Date(targetDate);

    if (isNaN(initial.getTime()) || isNaN(target.getTime())) {
      throw new Error("Formato de fecha inválido. Asegúrate de proporcionar fechas válidas.");
    }

    const timeDifference = target.getTime() - initial.getTime();

    if (timeDifference < 0) {
      throw new Error("La fecha objetivo es anterior a la fecha inicial.");
    }

    const totalSeconds = Math.floor(timeDifference / 1000);
    const days = Math.floor(totalSeconds / (60 * 60 * 24));
    const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  } catch (error) {
    return {
      error: error.message,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
};

/*****************************************************
 * La función startCyclicChange inicia un ciclo que
 * cambia de manera cíclica el índice de los elementos
 * proporcionados a intervalos de tiempo especificados.
 * ***************************************************/

const startCyclicChange = (length, intervalTime, onChange) => {
  if (length <= 1) return;
  let currentIndex = 0;

  const intervalId = setInterval(() => {
    currentIndex = (currentIndex + 1) % length;
    onChange(currentIndex);
  }, intervalTime);

  return intervalId;
};

const calculateCharacters = (container, averageCharWidth, averageLineHeight) => {
  const containerWidth = container.offsetWidth;
  const containerHeight = container.offsetHeight;

  if (!containerWidth || !containerHeight) return;

  const availableLines = Math.floor(containerHeight / averageLineHeight);
  const charsPerLine = Math.floor(containerWidth / averageCharWidth);

  return availableLines * charsPerLine;
};

export { dateLocaleFormat, showMessage, roleRestriction, dateLocaleFormatEs, transformRoleName, setTime, getTurnName, debounce, calculateTimeLeft, startCyclicChange, calculateCharacters };

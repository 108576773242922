import axios from "@/api/axios";

export default {
  namespaced: true,
  state: () => ({
    goals: [],
    modelGoals: [],
    hasMore: null,
  }),
  mutations: {
    updateModelGoals(state, data) {
      const modelIdx = state.modelGoals.findIndex((model) => model.modelData._id === data.modelData._id);
      if (modelIdx === -1) return;
      state.modelGoals.splice(modelIdx, 1, data);
    },
    updateStatusGoals(state, data) {
      state.goals = data;
    },
    updateGoals(state, data) {
      const goalIdx = state.goals.findIndex((goal) => goal._id === data._id);
      if (goalIdx === -1) return;
      state.goals.splice(goalIdx, 1, data);
    },
    restartGoal(state, goalId) {
      const goal = state.goals.find((goal) => goal._id === goalId);
      let awards = [];
      goal?.subGoals.forEach(({ award }) => awards.push(award.name));
      awards.push(goal?.award?.name);

      state.modelGoals = state.modelGoals.map((modelGoal) => {
        modelGoal.goals = modelGoal.goals.map((goal) => {
          if (goal.goalId === goalId) goal.progress = 0;
          return goal;
        });
        modelGoal.awards = modelGoal.awards.map((award) => {
          if (awards.includes(award.name)) award.finished = false;
          return award;
        });
        return modelGoal;
      });
    },
  },
  actions: {
    async initGoals({ state }) {
      const { data } = await axios.get("goals");
      state.goals = data;
    },
    async getModelGoals({ state }, payload) {
      if (payload?.reset) state.modelGoals = [];
      let { data } = await axios.get(`model/goals?limit=6&page=${payload?.page || 0}&city=${payload?.city || ""}`);
      state.modelGoals = data.modelGoals.length ? [...state.modelGoals, ...data.modelGoals] : "";
      state.hasMore = data.hasMore;
    },
    async getModelGoalsById({ state }, id) {
      const { data } = await axios.get(`model/goals/${id}`);
      if (!data) return;
      state.modelGoals = [data];
    },
    async updateModelGoal({ commit }, { id, ...info }) {
      try {
        const { data } = await axios.put(`model/goals/${id}`, info);
        commit("updateModelGoals", data);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async updateGoal({ commit }, { id, ...info }) {
      try {
        const { data } = await axios.put(`goals/${id}`, info);
        commit("updateGoals", data);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async modifyBadge({ commit }, { content, modelId, action }) {
      try {
        const { data } = await axios[action](`model/badges/${modelId}`, content);
        if (data.success) {
          commit("updateModelGoals", data.modelData);
          return { success: data.success, message: data.message };
        } else {
          return { success: data.success, message: data.message };
        }
      } catch (error) {
        return false;
      }
    },
  },
};

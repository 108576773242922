export default {
  namespaced: true,
  state: () => ({
    appsDb: [],
    env: process.env.VUE_APP_MODE,
    host: process.env.VUE_APP_HOST,
    showApps: false,
  }),

  actions: {
    /**
     * Metodo para obtener las aplicaciones de la base de datos.
     */
    async getApps({ commit }) {
      try {
        const { data } = await this.$axios("/apps");
        if (data?.apps?.length) {
          commit("updateApplications", data.apps);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    setShowApps({ commit }) {
      commit("setApps");
    },
  },
  getters: {
    getShowApps: (state) => state.showApps,
    /**
     * Obtener una aplicacion a traves de su key
     * @param { String } key
     * @returns
     */
    getAppByName: (state) => (key) => {
      return state.appsDb.find((app) => app.key === key);
    },
    /**
     * Obtener el ambiente de ejecucion
     * @returns
     */
    getEnvMode(state) {
      return state.env;
    },
    /**
     * Obtener un objeto solo con las keys y nombres
     */
    getAppsNames(state) {
      const names = state.appsDb.reduce((app, { name, key }) => {
        const appName = {
          autologin: "autologin",
          website: "Sitio web",
        };
        name = appName[key] ? appName[key] : name;
        name = name.charAt(0).toUpperCase() + name.slice(1);
        app[key] = name;
        return app;
      }, {});
      return names;
    },
    getAppsInformation(state) {
      return state.appsDb;
    },
  },
  mutations: {
    /**
     * Metodo para validar el ambiente en el que se va a ejecutar la app y actualizar el estado de appsDb.
     * @param { Array } payload
     */
    updateApplications(state, payload) {
      const { env, host } = state;
      const appRender = payload.map((app) => {
        app.api = app.api[env] ?? `${host}:${app.api.localPort}`;
        app.link = app.link[env] ?? `${host}:${app.link.localPort}`;
        return app;
      });
      state.appsDb = appRender;
    },
    setApps(state) {
      state.showApps = !state.showApps;
    },
  },
};

import store from "../store";

export default {
  namespaced: true,
  state: {
    poll: {},
    polls: [],
    pollsToResponse: [],
    skipPoll: false,
  },
  getters: {
    getPollsToResponse(state) {
      return state.pollsToResponse.polls && state.pollsToResponse.polls.length > 0 ? state.pollsToResponse.polls[0] : null;
    },
    getSkipPoll(state) {
      return state.skipPoll;
    },
    getPoll: (state) => state.poll,
  },
  mutations: {
    setPolls(state, payload) {
      state.polls = payload;
    },
    setPoll(state, payload) {
      state.poll = payload;
    },
    setPollsToResponse(state, payload) {
      state.pollsToResponse = payload;
    },
    updatePollsToResponse(state, update) {
      const userId = store.state.user.profile._id;
      const includesUser = update.voters.includes(userId);
      const updatePoll = state.pollsToResponse.polls.findIndex((poll) => poll._id === update._id);

      if (updatePoll !== -1 && includesUser) {
        state.pollsToResponse.polls.splice(updatePoll, 1);
        return;
      }
      if (!includesUser) {
        state.pollsToResponse.polls.push(update);
      }
    },
    setNewPoll(state, payload) {
      state.poll = payload;
    },
    updatePollsToSkip(state, payload) {
      if (payload.success) {
        localStorage.setItem("pollSkipTime", new Date());
      }
      state.skipPoll = payload;
    },
  },
  actions: {
    async getPoll({ commit }, query = "") {
      try {
        const { data } = await this.$axios.get(`${process.env.VUE_APP_API_IP}poll?${query}`);
        const savedPollSkipTime = localStorage.getItem("pollSkipTime");
        if (!savedPollSkipTime) {
          localStorage.setItem("pollSkipTime", new Date());
        }
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo obtener la encuesta");
      }
    },

    async getPollById({ commit }, _id) {
      try {
        const { data, error } = await this.$axios.get(`${process.env.VUE_APP_API_IP}poll/pollwithvoters/${_id}`);
        if (error) {
          throw Error(error);
        }
        commit("setPoll", data);
      } catch (error) {
        console.log("error :>> ", error);
      }
    },

    async createNewPoll({ commit }, newPoll) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
        const { data } = await this.$axios.post(`${process.env.VUE_APP_API_IP}poll`, newPoll, config);
        commit("setNewPoll", data);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo crear la nueva encuesta");
      }
    },
    async changePollStatus({ commit }, { id, status }) {
      try {
        const { data } = await this.$axios.post(`poll/update/${id}`, { status });
        if (data?.error) {
          throw new Error(data.error);
        }
        commit("setPoll", data);
        if (data.pollUpdated.status !== "pending" || data.pollUpdated.status !== "inactive") {
          commit("updatePollsToResponse", data.pollUpdated);
        }
      } catch (error) {
        this.$alerts.dataSendError({ title: "Error", message: error.message });
        console.log(error);
      }
    },
    async updateVoters({ commit }, { pollId, newVoters }) {
      try {
        const { data } = await this.$axios.put(`${process.env.VUE_APP_API_IP}poll/${pollId}/updateVoters`, newVoters);
        commit("setPoll", data);
      } catch (error) {
        console.log("Error en updateVoters:", error);
        throw new Error("No se pudo actualizar los votantes");
      }
    },
    async sendVote({ commit }, { pollId, answers }) {
      try {
        const { data } = await this.$axios.put(`poll/${pollId}/updateVoters`, answers);
        commit("updatePollsToResponse", data.updatedPoll);
      } catch (error) {
        console.log("Error en updateVoters:", error);
        throw new Error("No se pudo actualizar los votantes");
      }
    },
    async sendSkip({ commit }, { pollId }) {
      try {
        const { data } = await this.$axios.post(`poll/updateSkip/${pollId}`);

        commit("updatePollsToSkip", data);
      } catch (error) {
        console.log("Error en skip:", error);
        throw new Error("No se puede saltar la encuesta");
      }
    },
    async sendPolicies({ commit }, { id, ...props }) {
      try {
        const { data } = await this.$axios.post(`poll/update/${id}`, { ...props });
        return data;
      } catch (error) {
        console.log(error);
        throw new Error("No se pudo modificar el estado de la encuesta");
      }
    },
  },
};

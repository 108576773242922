import Vue from "vue";
import { BootstrapVue, IconsPlugin, BVToastPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "../router";
import store from "../store";
import "@/assets/sass/base/_fonts.scss";
import "@/assets/sass/main.scss";
import "./registerServiceWorker";
import "../js/prevent";
import api from "../api/api";
import axios from "@/api/axios";
import Swal from "sweetalert2";
import alerts from "@/js/swalAlerts";
import global from "../js/global";
import socket from "../js/socket";
import iconic from "../plugins/iconic";
import Avatar from "@/components/widgets/Avatar.vue";
import VMdDateRangePicker from "v-md-date-range-picker";
import VueChartkick from "vue-chartkick";
import Highcharts from "@/common/highcharts";
import vsPagination from "vuesax";
import "vuesax/dist/vuesax.css";
import globalData from "../mixins/globalData";
import validationsRoles from "../mixins/roles";
import validate from "@/assets/js/validate";
import { globals } from "../plugins/globals";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Carousel3d from "vue-carousel-3d";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import AvatarStatus from "../common/Header/AvatarStatus.vue";

store.$axios = axios;
store.$router = router;
store.$alerts = alerts;

Vue.mixin(globals);
Vue.use(VueChartkick.use(Highcharts));
Vue.use(Toast, {
  position: "bottom-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  icon: true,
  rtl: false,
});
Vue.use(Carousel3d);

Vue.config.productionTip = false;
Vue.prototype.$global = global;
Vue.prototype.$api = api;
Vue.prototype.$axios = axios;
Vue.prototype.$Swal = Swal;
Vue.prototype.$socket = socket;
Vue.prototype.$alerts = alerts;
Vue.prototype.$validate = validate;
// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(BVToastPlugin);
// Optionally install the BootstrapVue icon components plugin
Vue.component("Avatar", Avatar);
Vue.component("AvatarStatus", AvatarStatus);
Vue.use(IconsPlugin);
Vue.use(iconic);
// Install VMdDateRangePicker plugin de vue
Vue.use(VMdDateRangePicker);
Vue.use(vsPagination, {
  colors: {
    primary: "#bd0909",
  },
});
Vue.mixin(globalData);
Vue.mixin(validationsRoles);

new Vue({
  beforeCreate: async function() {
    await store.dispatch("user/startAuthUser");
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  },
});

export default {
  namespaced: true,
  state: () => ({
    usersInRooms: {},
    rankingRooms: [],
    rankingModels: [],
    hoursPerCity: [],
    hourAndDay: [],
    occupancyOffices: [],
    hoursInRooms: [],
    loadings: {
      usersInRooms: true,
      rankingRooms: true,
      rankingModels: true,
      hoursPerCity: true,
      hourAndDay: true,
      occupancyOffices: true,
      hoursInRooms: true,
    },
  }),
  mutations: {
    changeLoading(state, payload) {
      Object.keys(state.loadings).forEach((key) => (state.loadings[key] = payload));
    },
    changeLoadingIn(state, { name, value }) {
      state.loadings[name] = value;
    },
    updateOccupationPercentage(state, payload) {
      state.usersInRooms = payload || {};
      state.loadings["usersInRooms"] = false;
    },
    updateRankingRooms(state, payload) {
      state.rankingRooms = payload || [];
      state.loadings["rankingRooms"] = false;
    },
    updateRankingModels(state, payload) {
      state.rankingModels = payload || [];
      state.loadings["rankingModels"] = false;
    },
    updateHoursPerCity(state, payload) {
      state.hoursPerCity = payload || [];
      state.loadings["hoursPerCity"] = false;
    },
    updateHoursAndDay(state, payload) {
      state.hourAndDay = payload || [];
      state.loadings["hourAndDay"] = false;
    },
    updateOccupancyOffices(state, payload) {
      state.occupancyOffices = payload || [];
      state.loadings["occupancyOffices"] = false;
    },
    updateHoursInRooms(state, payload) {
      state.hoursInRooms = payload || [];
      state.loadings["hoursInRooms"] = false;
    },
    initValuesStatistics(state) {
      state.usersInRooms = {};
      state.rankingRooms = [];
      state.rankingModels = [];
      state.hoursPerCity = [];
      state.hourAndDay = [];
      state.occupancyOffices = [];
      state.hoursInRooms = [];
    },
  },
  actions: {
    async getStatistics({ commit }, query) {
      commit("changeLoading", true);
      commit("initValuesStatistics");
      try {
        const { data } = await this.$axios.get(`/operation/getStatistics?${query}`);
        const { error, response } = data;

        if (error) {
          throw new Error(error);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  getters: {
    usersInRooms: (state) => state.usersInRooms,
    rankingRooms: (state) => state.rankingRooms,
    rankingModels: (state) => state.rankingModels,
    hoursPerCity: (state) => state.hoursPerCity,
    hourAndDay: (state) => state.hourAndDay,
    occupancyOffices: (state) => state.occupancyOffices,
    hoursInRooms: (state) => state.hoursInRooms,
    loadingIn: (state) => (key) => state.loadings[key],
  },
};

<script>
  export default {
    props: ["user", "containerWidth", "containerHeight"],
    computed: {
      stateUser() {
        let { isActive, isOnline } = this.user;
        return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
      },
    },
  };
</script>

<template>
  <section :class="`avatarStatus avatarStatus--${stateUser}`" :style="`width: ${containerWidth}px; height: ${containerHeight}px`">
    <Avatar class="avatarStatus__avatar" :user="user" />
    <span :class="`avatarStatus__statusCircle avatarStatus__statusCircle--${stateUser}`" />
  </section>
</template>

<style lang="scss" scoped>
  .avatarStatus {
    $online: #209e0c;
    $away: #fe9920;
    $offline: #d8d8d8;

    position: relative;
    @include Flex(row);
    border: 2px solid $offline;
    border-radius: 50%;
    &--online {
      border-color: $online;
    }
    &--away {
      border-color: $away;
    }
    &__avatar {
      width: 100%;
      height: 100%;
      font-size: 18px;
      border: 1px solid #fff;
    }
    &__statusCircle {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 8px;
      height: 8px;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: $offline;
      &--online {
        background-color: $online;
      }
      &--away {
        background-color: $away;
      }
    }
  }
</style>

<script>
  import { mapGetters, mapActions } from "vuex";
  import AppsList from "../components/header/AppsAvailable.vue";
  import Card from "./newHome/Card.vue";
  import Swal from "sweetalert2";
  import updSession from "@/common/UpdSession";
  import { getMain, getHost, goToApp, getApp, verifyAppRequireIp } from "@/common/app.main.js";
  import PresentationNew from "./PresentationNew.vue";

  export default {
    components: { AppsList, Card, PresentationNew },
    data() {
      return {
        tokenName: process.env.VUE_APP_TOKEN_NAME,
        gretings: [
          { start: 0, end: 12, bg: "BuenosDias", greeting: "goodMorning", text: "Buenos días" },
          { start: 12, end: 18, bg: "BuenasTardes", greeting: "goodAfternoon", text: "Buenas tardes" },
          { start: 18, end: 23, bg: "BuenasNoches", greeting: "goodNight", text: "Buenas noches" },
        ],
        actions: [
          {
            icon: "user",
            iconClass: "primary",
            title: "Ver perfil",
            content: "Valida tus datos y conexiones en tu perfil.",
            action: () => this.goRoute("/account"),
            show: ["employee", "superadmin", "coordinator", "monitor"],
          },
          {
            icon: "cardHelpDesk",
            iconClass: "primary",
            title: "Crea un ticket en mesa de ayuda",
            content: "Realiza solicitudes a TI, administracion, desarrollo.",
            action: () => this.goRoute("/helpdesk"),
            show: ["employee", "coordinator", "monitor", "model"],
          },
          {
            icon: "calendar",
            iconClass: "primary",
            title: "Programa tus reservas",
            content: "Aumenta tu trafico con mas horas en linea.",
            action: () => this.redirectTo("reservations", "/reservations"),
            show: ["model"],
          },
          {
            icon: "transmission",
            iconClass: "primary",
            title: "En vivo",
            content: "Revisa el estado de ocupación en las habitaciones.",
            action: () => this.goRoute("/occupation"),
            show: ["superadmin"],
          },
          {
            icon: "cardHelpDesk",
            iconClass: "primary",
            title: "Wiki Soporte",
            content: "Revisa la documentación y guías del equipo de soporte.",
            action: () => this.openLinkInNewTab("https://wiki.models1a.com"),
            show: ["employee", "superadmin", "coordinator", "monitor"],
          },
        ],
        updates: [
          {
            icon: "newUpdate",
            iconClass: "success",
            title: "¡Nueva Interfaz de Inicio!",
            content:
              "Explora la renovada pantalla de inicio de nuestra plataforma, diseñada para mejorar tu experiencia. Encuentra todas tus aplicaciones y acciones rápidas en un solo lugar, con un acceso más intuitivo y rápido. ¡Descubre lo fácil que es ahora gestionar tu día a día!",
            show: ["model"],
          },
          {
            icon: "newUpdate",
            iconClass: "success",
            title: "¡Lanzamos la Nueva Wiki de Soporte!",
            content:
              "Nuestra nueva Wiki de Soporte está aquí para ayudarte a aprovechar al máximo nuestra plataforma. Accede a guías, preguntas frecuentes y artículos actualizados que te permitirán resolver tus dudas al instante. ¡Facilita tu día a día con esta nueva herramienta!",
            show: ["employee", "superadmin", "coordinator", "monitor"],
          },
        ],
        showModal: false,
        new: [],
      };
    },
    computed: {
      ...mapGetters("user", { getProfile: "getProfile" }),
      ...mapGetters("applications", { getAppByName: "getAppByName" }),
      nameToShow() {
        const { artisticName, user } = this.getProfile;
        return artisticName || user;
      },
      greting() {
        const currentHour = new Date().getHours();
        const currentGreting = this.gretings.find(({ start, end }) => start <= currentHour && end >= currentHour);
        const backgroundStyle = `https://new-web-site-bucket.s3.amazonaws.com/myspace/apps/${currentGreting.bg}New.jpg`;

        return { ...currentGreting, backgroundStyle };
      },
      isDesktop() {
        return this.$screen.width >= 768;
      },
      getMyActions() {
        const { role } = this.getProfile;

        return this.filterByRole(this.actions, role);
      },
      getMyUpdates() {
        const { role } = this.getProfile;

        return this.filterByRole(this.updates, role);
      },
    },
    methods: {
      ...mapActions("applications", { getApps: "getApps" }),
      ...mapActions("skipNewsHome", { getSkip: "getSkip", getSkipById: "getSkipById" }),
      ...mapActions("news", { fetchLastNews: "fetchLastNews" }),
      filterByRole(documents, role){
        return documents.filter(({ show }) => show.some((rolesToShow) => rolesToShow === role));
      },
      verifyRedirect() {
        const { app, redirect } = this.$route.query;

        if (!app || !redirect) {
          return;
        }

        const sstk = this.getProfile.SSID;
        const appData = this.getAppByName(app);
        const appHost = this.$global.getHost(appData.link);
        const path = `${appHost}/auth/?redirect=${redirect}&app=${app}&sstk=${sstk}`;

        location.href = path;
      },
      redirectTo(app, route) {
        const appName = process.env.VUE_APP_KEY_NAME;
        const isDesktopApp = this.$store.state.isDesktopApp;
        const appData = getApp(app);

        goToApp(appData, this.getProfile, this.tokenName, appName, isDesktopApp, route);
      },
      openLinkInNewTab(url) {
        window.open(url, '_blank', 'noopener');
      },
      async goRoute(route) {
        const appData = getMain();
        const verifyIp = await verifyAppRequireIp(appData, this.getProfile, this.tokenName);

        if (!verifyIp) {
          Swal.fire({
            title: "Lo sentimos no se encuentra en ninguna de nuestras sedes",
            text: "Una vez te encuentres ubicado en alguna de nuestras sedes podrás acceder sin problema",
            icon: "info",
            iconColor: "#bd0909",
            confirmButtonColor: "#bd0909",
          });
          return;
        }

        this.$router.push(route);
      },
      initApp() {
        localStorage.removeItem("ipAllowed");
      },
      closeSession() {
        const baseAppUrl = getHost();
        if (process.env.VUE_APP_KEY_NAME !== getMain().key) {
          localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
        }
        location.href = `${baseAppUrl}/logout/?user=${this.$user._id}&sstk=${this.$user.SSID}`;
      },
      closeModal() {
        this.showModal = false;
      },
    },
    async created() {
      updSession();
      await this.getApps();
      this.verifyRedirect();
    },
    async beforeMount() {
      this.initApp();
      const { news, showLastNew } = await this.fetchLastNews();
      this.showModal = showLastNew;
      if (news) {
        this.new.push(news);
      }
    },
  };
</script>

<template>
  <div class="appsHome" :style="{ 'background-image': `url(${greting.backgroundStyle})` }" v-if="getProfile">
    <PresentationNew v-if="showModal" :new="this.new" @hiddenModal="closeModal" />
    <div class="appsHome__wrapper">
      <div class="appsHome__section">
        <div class="appsHome__greting">
          <div class="appsHome__gretingText">
            <iconic :class="`appsHome__greetingIcon appsHome__greetingIcon--${greting.greeting}`" :name="greting.greeting" />
            <p class="appsHome__text">{{ greting.text }}</p>
          </div>
          <div class="appsHome__door fade-in" @click="closeSession" v-if="!isDesktop" />
        </div>
        <div class="appsHome__person">
          <Avatar class="appsHome__avatar borderImageMedium" :user="getProfile" />
          <label class="appsHome__label">{{ nameToShow }}</label>
        </div>
        <div class="appsHome__listApps" v-if="!isDesktop">
          <label class="appsHome__title">Aplicaciones</label>
          <AppsList />
          <p class="appsHome__name" capitalize>Models 1A</p>
        </div>
        <div class="appsHome__actionsWrapper">
          <div class="appsHome__actions" v-if="getMyActions.length">
            <p class="appsHome__action">Acciones rapidas</p>
            <Card class="appsHome__card" v-for="(data, index) in getMyActions" :key="index" :dataCard="data" />
          </div>
          <div class="appsHome__actions" v-if="getMyUpdates.length">
            <div class="appsHome__update appsHome__action">
              <iconic name="settings" class="appsHome__settings" />
              <p>Actualizaciones en la plataforma</p>
            </div>
            <Card class="appsHome__CardUpd" v-for="(update, index) in getMyUpdates" :key="index" :dataCard="update" />
          </div>
        </div>
        <art dir="bottom" />
        <user-menu ref="menu" :user="getProfile" addClass="menu-absolute" />
      </div>
      <div class="appsHome__section" v-if="isDesktop">
        <div class="appsHome__contentClose">
          <div class="appsHome__door fade-in" @click="closeSession" />
        </div>
        <div class="appsHome__listApps">
          <label class="appsHome__title">Aplicaciones</label>
          <AppsList />
          <p class="appsHome__name" capitalize>Models 1A</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .appsHome {
    @include Flex(row);
    width: 100vw;
    min-height: 100vh;
    padding: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    &__wrapper {
      @include Flex(column, flex-start);
      width: 100%;
      height: 100%;
      gap: 10px;
    }
    &__section {
      @include Flex(column, flex-start, center);
      width: 100%;
      max-width: 570px;
      gap: 20px;
    }
    &__actionsWrapper {
      @include Flex(column, flex-start, center);
      width: 100%;
      gap: 20px;
    }
    &__greting {
      @include Flex(row, space-between);
      width: 100%;
      gap: 10px;
    }
    &__gretingText {
      @include Flex(row);
      gap: 10px;
    }
    &__greetingIcon {
      font-size: 40px;
      &--goodMorning {
        color: #ef5a24;
      }
      &--goodAfternoon {
        color: #ffb813;
      }
      &--goodNight {
        color: #2384cc;
      }
    }
    &__text {
      font-size: 27px;
      font-family: $newFont;
    }
    &__door {
      position: relative;
      width: 35px;
      height: 35px;
      padding: 10px;
      border-radius: 15px;
      background: url("../../src/assets/img/logOut.svg");
      background-size: cover;
      background-repeat: no-repeat;
      transition: all ease-in-out 0.3s;
      cursor: pointer;
      &:hover {
        background: #ebebeb url("../../src/assets/img/logOutHover.svg");
        background-size: cover;
        background-repeat: no-repeat;
        transition: all ease-in-out 0.3s;
        z-index: 2;
      }
    }
    &__person {
      @include Flex(row);
      width: 100%;
      gap: 10px;
    }
    &__avatar {
      width: 55px;
      height: 55px;
    }
    &__label {
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 27px;
      font-family: $newFont;
    }
    &__listApps {
      @include Flex(column);
      padding-top: 20px;
      gap: 20px;
    }
    &__title {
      font-size: 25px;
      font-family: $newFont;
    }
    &__name {
      color: rgba(189, 9, 9, 0.2);
      -webkit-text-stroke-width: 0.10000000149011612;
      -webkit-text-stroke-color: rgba(189, 9, 9, 0.2);
      font-family: $third_font;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__actions {
      @include Flex(column);
      width: 100%;
      max-width: 570px;
      padding: 15px;
      gap: 10px;
    }
    &__action {
      width: 100%;
      font-size: 16px;
      padding-bottom: 10px;
      color: #272727;
      font-family: $newFont;
    }
    &__update {
      @include Flex(row, flex-start);
      width: 100%;
      gap: 25px;
    }
    @media screen and (min-width: 768px) {
      &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        max-width: 1500px;
        max-height: 700px;
      }
      &__greetingIcon {
        font-size: 50px;
      }
      &__section {
        height: 100%;
        gap: 25px;
      }
      &__actionsWrapper {
        gap: 25px;
        overflow: auto;
        max-height: calc(100% - 190px);
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &__actions {
        padding: 0;
      }
      &__person {
        justify-content: flex-start;
      }
      &__contentClose {
        @include Flex(row, flex-end);
        width: 100%;
      }
      &__name {
        font-size: 42px;
        color: $primary-color;
      }
      &__door {
        @include Flex(row);
        transition: all ease-in-out 0.3s;
        &:hover::after {
          content: "Cerrar sesión";
          @include Flex(row);
          position: absolute;
          right: 40px;
          padding: 0 15px;
          height: 100%;
          font-family: $newFont;
          white-space: nowrap;
          background: #ebebeb;
          border-radius: 10px;
          z-index: 1;
        }
      }
    }
    @media screen and (min-width: 1024px) {
      gap: 0;
      &__text {
        font-size: 43px;
      }
      &__avatar {
        width: 70px;
        height: 70px;
      }
      &__label {
        font-size: 35px;
        max-width: 450px;
      }
      &__name {
        font-size: 45px;
      }
      &__title {
        font-size: 35px;
      }
    }
  }
</style>

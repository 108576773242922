import appApi from "@/api/appApi.js";
import axios from "@/api/axios";
import { isCoorSpecial } from "@/js/validation";

export default (appApiKey) => ({
  namespaced: true,

  state: {
    data: [],
    loading: true,
    error: null,
    selectsOptionsMonitors: [],
  },

  actions: {
    search: async ({ commit }, query) => {
      const response = await appApi[appApiKey].search(query);
      response.data.error ? commit("setError", response.data.error) : commit("setData", response.data);
    },
    fetch: async ({ commit }, query) => {
      commit("setLoading", true);
      const response = await appApi[appApiKey].fetch(query);
      if (response.data.error) commit("setError", response.data);
      else if (response.data.rooms) commit("setData", response.data.rooms);
      else if (response.data.users) commit("setData", response.data.users);
      else commit("setData", response.data);
    },
    async fetchUsers({ commit }, query) {
      commit("setLoading", true);
      const response = await appApi[appApiKey].fetch(query);
      if (response.data.error) {
        commit("setError", response.data);
      } else {
        commit("setData", response.data.users);
      }
    },
    async fetchMonitors({ commit }) {
      const resp = await axios.get("/users?monitorSelect=true");
      commit(
        "setSelectsOptionsMonitors",
        resp.data.users.filter((user) => user.role === "monitor" || isCoorSpecial(user))
      );
    },
    create: async ({ commit }, data) => {
      commit("setLoading", true);
      const response = await appApi[appApiKey].create(data);
      response.data.error ? commit("setError", response.data) : commit("setData", response.data);
    },
    update: async ({ commit }, { id, data }) => {
      commit("setLoading", true);
      const response = await appApi[appApiKey].update(id, data);
      response.data.error ? commit("setError", response.data) : commit("update", response.data);
    },
  },

  mutations: {
    setError(state, item) {
      state.error = item;
      state.data = [];
    },
    setLoading(state) {
      state.loading = state;
    },
    setData(state, data) {
      state.error = null;
      state.data = data;
      state.loading = false;
    },
    setSelectsOptionsMonitors(state, data) {
      state.selectsOptionsMonitors = data;
    },
    setCurrent(state, item) {
      state.current = item;
      state.loading = false;
    },
    update(state, item) {
      state.error = null;
      state.current = item;
      const index = state.data.findIndex((theItem) => item._id === theItem._id);
      if (index !== -1) {
        state.data.splice(index, 1, item);
      }
      state.loading = false;
    },
    add(state, item) {
      state.error = null;
      state.data.push(item);
      state.loading = false;
    },
    removeProduct(state, payload) {
      const index = state.data.findIndex((item) => item._id === payload.data._id);
      state.data.splice(index, 1);
      state.loading = false;
    },
  },
  getters: {
    getData: (state) => state.data,
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
    getModels: (state) => state.data.filter((item) => item.role === "model"),
    getMonitors: (state) => state.data.filter((item) => item.role === "monitor" || isCoorSpecial(item)),
    getSelectsOptionsMonitors: (state) => {
      const options = state.selectsOptionsMonitors
        .filter((user) => user?.isEnable)
        .map((user) => ({
          value: user._id,
          text: user.user,
        }))
        .sort((a, b) => (a?.text?.toLowerCase() > b?.text?.toLowerCase() ? 1 : -1));
      return [{ value: "", text: "Seleccione...", disabled: true }, ...options];
    },
  },
});

export default {
  modelBreak: async (modelEmail, profile, _this) => {
    if (profile.email === modelEmail) {
      _this.$root.$emit("runWatch");
      if (_this.$route.name !== "account") {
        await _this.$router.push("/account");
      }
      const modal = document.getElementById("breakModal");
      modal.style.display = "block";
    }
  },
};

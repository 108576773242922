import socketIo from "socket.io-client";
import { getMain } from "@/common/app.main";

let [socket, host, query] = [null, getMain().api, ""];

const initSocket = (user, cb) => {
  const store = require("@/store/index");

  if (!user) {
    user = store.default?.state?.user;
  }

  if (!user) {
    return;
  }

  const { role, _id, isSpecialFunctions, area, city } = user;

  host = host.split("api/v")[0];

  query += `&isSpecialFunctions=${isSpecialFunctions}`;

  const token = store.default?.state?.user?.token || localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);

  socket = socketIo(host, {
    auth: { [process.env.VUE_APP_TOKEN_NAME]: token },
    query: `role=${role}&_id=${_id}&area=${area}&city=${city}${query}`,
    path: "/api/v1/socket",
  });

  return new Promise((resolve) => {
    socket.on("connect", () => {
      if (cb) {
        try {
          cb(socket);
        } catch (error) {
          console.log("initSocket", error);
        }
      }
      resolve(socket);
    });
  });
};

export { initSocket, socket };

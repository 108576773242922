import dictionary from "./dictionary";

/**
 * Función que convierte un número a un número String Time.
 * @param {number} time Número entero
 * @returns String con el número correspondiente
 */
const timeToString = function(time) {
  return time.toString(10).length === 1 ? "0" + time.toString() : time.toString(10);
};

export default {
  getMondayOfCurrentWeek() {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(today.setDate(diff));
  },
  getTimeFromSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const seconds_left = seconds % 60;
    return `${hours}:${minutes}:${seconds_left | 0}`;
  },
  milliSecondsToTime: (time) => {
    if (!time) return 0;
    const addZero = (num) => {
      return (num < 10 && "0") + num;
    };
    const ms = time % 1000;
    time = (time - ms) / 1000;
    const secs = time % 60;
    time = (time - secs) / 60;
    const mins = time % 60;
    const hrs = (time - mins) / 60;
    return `${addZero(hrs)}:${addZero(mins)}:${addZero(secs)}`;
  },
  setSrc: (data) => {
    let el = document.querySelector(data.el);
    el.src = data.src;

    let elems = document.querySelectorAll(".product-view-img-wrap .product-view-gallery-img-card");
    [].forEach.call(elems, function(el) {
      el.classList.remove("selected");
    });

    let selelems = document.querySelectorAll(`.product-view-img-wrap .` + data.mrk);
    [].forEach.call(selelems, function(el) {
      el.classList.add("selected");
    });
  },
  toggleClass: (data) => {
    let el = document.querySelector(data.el);
    let clss = data.class;
    el.classList.toggle(clss);
  },
  convertDateToString(date) {
    const option = { month: "2-digit", day: "2-digit", year: "numeric" };
    let _date = typeof date === "string" ? new Date(date) : date;
    return _date
      .toLocaleDateString("es-ES", option)
      .split("/")
      .reverse()
      .join("-");
  },
  dateLocaleFormat: (date, options) => {
    date = new Date(date);
    const ulang = localStorage.getItem("user-language")?.substring(0, 2) || navigator.language;
    const _options = options || {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString(ulang, _options);
  },
  timeAMPM: (time) => {
    const myTime = time.split(":");
    let hours = myTime[0];
    let minutes = myTime[1];
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },
  monthName: function(date) {
    date = new Date(date);
    const ulang = localStorage.getItem("user-language").substring(0, 2) || navigator.language.substring(0, 2);
    const options = {
      month: "long",
    };
    return date.toLocaleDateString(ulang, options);
  },
  toInputDate: function(date) {
    if (date && date.includes("T")) {
      date = date.split("T")[0];
    }
    return date;
  },
  toInputDateTime: function(date) {
    if (date && date.includes("Z")) {
      date = date.split("Z")[0];
    }
    return date;
  },
  yearsDiff: (d1, d2) => {
    let yearsDiff = new Date(new Date(d2) - new Date(d1)).getFullYear() - 1970;
    return yearsDiff;
  },
  daysDiff: (d1, d2) => {
    const initDate = new Date(d1).getTime();
    const finalDate = new Date(d2).getTime();

    const diff = initDate - finalDate;
    const diffInDays = diff / (1000 * 60 * 60 * 24);

    return diffInDays;
  },
  sumDaysToDate(d, days) {
    const date = new Date(d);
    date.setDate(date.getDate() + days);
    return date.toISOString().substring(0, 10);
  },
  getYearsFromDays(days) {
    return Math.floor(days / 365);
  },
  getMonthsFromDays(days) {
    return days / 30.41;
  },
  getMainImg: (data) => {
    var found = data.find(function(element) {
      if (element.isMain === true) return element;
    });
    if (found) {
      return found.link;
    }
  },
  getWorkshiftDates(date) {
    const _date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const turn = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate(), 6, 0, 0);

    return {
      morning: { from: new Date(turn), to: new Date(turn.setHours(turn.getHours() + 8)) },
      late: { from: new Date(turn), to: new Date(turn.setHours(turn.getHours() + 8)) },
      night: { from: new Date(turn), to: new Date(turn.setHours(turn.getHours() + 8)) },
    };
  },
  datesDiff: function(d1, d2) {
    const initDate = new Date(d1).getTime();
    const finalDate = new Date(d2).getTime();

    const diff = initDate - finalDate;
    const diffInDays = diff / (1000 * 60 * 60 * 24);

    const days = diffInDays;
    const message = this.parseDaysToDate(days);
    return message;
  },
  validFile: function(file, typeFile) {
    switch (typeFile) {
      case "image":
        const typeImageRgx = /(\.(png|jpeg|jpg|gif))$/i;
        return typeImageRgx.test(file[0]?.name);
      case "video":
        const typeVideoRgx = /(\.(avi|divx|flv|m4v|mkv|mov|mp4|mpeg|mpg|ogm|ogv|ogx|rm|rmvb|smil|webm|wmv|xvid))$/i;
        return typeVideoRgx.test(file[0].name);
      case "file":
        const typeFileRgx = /(\.(doc|docx|docm|pdf|pptx|txt|xlsx))$/i;
        return typeFileRgx.test(file[0].name);
    }
    return;
  },
  parseDaysToDate: function(days, opts = { hideDays: false }) {
    const { hideDays } = opts;
    const daysInAYear = 365;
    const daysInAMonth = 30.41;

    let count = days;

    const years = Math.floor(count / daysInAYear);
    if (years >= 0) {
      count = count - years * daysInAYear;
    }

    const months = Math.floor(count / daysInAMonth);
    if (months >= 0) {
      count = count - months * daysInAMonth;
    }

    const totalDays = Math.floor(count);
    const yearsMessage = years ? `${years} años` : "";
    const monthsMessage = months ? `${months} meses` : "";
    const daysMessage = totalDays ? `${totalDays} dias` : "";
    const result = `${yearsMessage} ${monthsMessage} ${!hideDays ? daysMessage : ""}`;

    return result.trim();
  },
  /**
   *
   * @param {String} timeString1 string hora del formato HH:mm:ss
   * @param {String} timeString2 string hora del formato HH:mm:ss
   * @returns sumatoria hora y convertido a String con formato HH:mm:ss
   */
  sumTime: function(timeString1, timeString2) {
    let time1 = timeString1.split(":");
    let time2 = timeString2.split(":");

    //Convertir a segundos y sumar
    let hours = (parseInt(time1[0]) + parseInt(time2[0])) * 3600;
    let minutes = (parseInt(time1[1]) + parseInt(time2[1])) * 60;
    let seconds = parseInt(time1[2]) + parseInt(time2[2]);

    //Reversar la conversión
    let secondsR = Math.floor(seconds);
    let minutesR = Math.floor(seconds / 60 + minutes / 60);
    let hoursR = Math.floor((minutesR * 60 + hours) / 3600);

    return [timeToString(hoursR), timeToString(minutesR % 60), timeToString(secondsR % 60)].join(":");
  },
  getInputValue: function(value, type) {
    let count = value;
    if (type === "dollars") return value;
    if (type === "days") {
      const years = Math.floor(count / 365);
      years ? (count = count - years * 365) : count;
      const months = Math.floor(count / 30.41);
      months ? (count = count - months * 30.41) : count;
      const days = Math.floor(count);
      return `${years}a ${months}m ${days}d`;
    }

    if (type === "hours") {
      const hours = Math.floor(count / 60);
      hours ? (count = count - hours * 60) : count;
      const minutes = Math.floor(count);
      return `${hours}h ${minutes}min`;
    }
  },
  parseInputToTime: function(value, type) {
    if (type === "hours") {
      const match = /(\d+h{1}|\d+min{1})?\s?(\d+min{1})?/.exec(value);
      if (match[0]) {
        const coincidences = match[0]?.split(" ");

        let mins = 0;
        coincidences.forEach((coincidence) => {
          if (coincidence) {
            const text = /(h|min)/.exec(coincidence)[0];
            const num = /\d+/.exec(coincidence)[0];
            mins += this.getTime(text, +num, "hours");
          }
        });
        return { message: this.parseMinutesToTime(mins), mins };
      }
      return { message: "", days: 0 };
    }
    if (type === "days") {
      const match = /(\d+a{1}|\d+m{1})?\s?(\d+m{1})?\s?(\d+d{1})?/.exec(value);
      if (match[0]) {
        const coincidences = match[0]?.split(" ");

        let days = 0;
        coincidences.forEach((coincidence) => {
          if (coincidence) {
            const text = /(a|m|d)/.exec(coincidence)[0];
            const num = /\d+/.exec(coincidence)[0];
            days += this.getTime(text, +num, "days");
          }
        });
        return { message: this.parseDaysToDate(days), days };
      }
      return { message: "", days: 0 };
    }
  },
  getTime: function(text, num, type) {
    if (type === "hours") {
      if (text === "h") {
        return num * 60;
      }
      if (text === "min") {
        return num;
      }
    }
    if (type === "days") {
      if (text === "a") {
        return num * 365;
      }
      if (text === "m") {
        return Math.ceil(num * 30.41);
      }
      if (text === "d") {
        return Math.floor(num);
      }
    }
  },
  parseMinutesToTime: function(mins) {
    const minsInAHour = 60;

    let count = mins;
    const hours = Math.floor(count / minsInAHour);
    if (hours >= 0) {
      count = count - hours * minsInAHour;
    }

    const hoursMessage = hours ? `${hours} horas` : "";
    const minutesMessage = count ? `${count} minutos` : "";
    const result = `${hoursMessage} ${minutesMessage}`;

    return result.trim();
  },
  getDateYMD: () => {
    return new Date().toISOString().substring(0, 10);
  },
  formatPrice: ({ value, currency = "USD" }) => {
    const formattedValue = new Intl.NumberFormat("en-EN", {
      style: "currency",
      currency: currency,
    }).format(value);
    return formattedValue;
  },

  getMainlang: (data, lang) => {
    var found = data.find(function(element) {
      if (element.languageId === lang) return element;
    });
    if (found) {
      return found;
    }
  },
  validEmail: (email) => {
    if (/^[^@ ]+@[^@ ]+\.[^@ \.]{2,}$/.test(email)) {
      return true;
    }
  },
  validPhone: (phone) => {
    if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)) {
      return true;
    }
  },
  getShort: (text, limit) => {
    let ellipsis = text.length > limit ? "..." : "";
    text = text.replace(/(<([^>]+)>)/gi, " ");
    return typeof text !== "string" ? text : text.substring(0, limit) + ellipsis;
  },
  linkIsActive: ($route, input) => {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some((path) => {
      return $route.fullPath.indexOf(path) === 0; // current path starts with this path string
    });
  },
  linkInclude: ($route, input) => {
    if ($route && input) {
      if ($route.fullPath.includes(input)) {
        return true;
      }
    }
    return false;
  },
  scrollToEl: (data) => {
    let el = document.querySelector(data.el);
    let y = el.offsetTop + -data.topgap;
    window.scroll({ top: y, behavior: data.behavior });
  },
  fastID: () => {
    function chr4() {
      return Math.random()
        .toString(16)
        .slice(-4);
    }
    return chr4() + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + chr4() + chr4();
  },
  hasSome: function(data1, data2) {
    if (typeof data1 === "object" && typeof data2 === "object") {
      return data1.some((some) => data2.includes(some));
    }
  },
  nameInitials: (name) => {
    return name ? name[0].charAt(0) : name;
  },
  dictionary: {
    ...dictionary.dictionary,
  },
  getHost: function(url) {
    const theurl = new URL(url);
    return theurl.origin;
  },
  validateInput: function(type, refError, value, message, role) {
    const regex = {
      numeric: /^[0-9]*$/,
      alphabetic: /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g,
      email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      noneSpecial: /^[a-zA-Z0-9]*$/,
    };
    if (value === "") {
      if (role === "model" && type === "numeric") return true;
      refError.innerText = `Ingrese ${message}`;
      return false;
    }
    if (!regex[type].test(value)) {
      refError.innerText = `${formatString(message)} inválido`;
      return false;
    }
    refError.innerText = "";
    return true;
  },
  clickOnBottom({ el, onBottom, gap = 0 }) {
    function isScrolling() {
      const { scrollTop, offsetHeight, scrollHeight } = el;
      const scrolledToBottom = scrollTop + offsetHeight + gap >= scrollHeight;
      if (scrolledToBottom) {
        onBottom();
      }
    }
    el.removeEventListener("scroll", isScrolling);
    el.addEventListener("scroll", isScrolling);
  },
  objectToQuery: function(obj) {
    return Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join("&");
  },
  currentYear: () => {
    return new Date().getFullYear();
  },
  isFalsy: (value) => {
    const falsy = [null, undefined, "", 0, false, NaN, "false", "undefined", "null", "NaN", "0"];
    return falsy.includes(value);
  },
  convertToType: (value, type) => {
    if (type === "number") {
      return Number(value);
    }

    if (type === "boolean") {
      return value === "true";
    }

    if (type === "string") {
      return String(value);
    }

    return value;
  },
  canAccessToModel: (user, model) => {
    const myPolices = user.polices;

    if (!myPolices) return true;

    if (!myPolices["models-stars-limit"]) return true;

    const policeStatus = myPolices["models-stars-limit"].status;

    if (!policeStatus) return true;

    const [myRating, modelRating] = [+myPolices["models-stars-limit"].value, +model.qualification];

    if (modelRating <= myRating) return true;

    return false;
  },
  getElapsedTime: (date) => {
    const now = new Date();
    const elapsedMilliseconds = now - date;

    const seconds = Math.floor(elapsedMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      const restMonths = Math.floor(months % 12);
      const restYears = Math.floor(years);

      return `${restYears} año${restYears !== 1 ? "s" : ""}, ${restMonths} mes${restMonths !== 1 ? "es" : ""}`;
    } else if (months > 0) {
      const restDays = Math.floor(days % 30);
      const restMonths = Math.floor(months % 12);

      return `${restMonths} mes${restMonths !== 1 ? "es" : ""}, ${restDays} día${restDays !== 1 ? "s" : ""}`;
    } else if (days > 0) {
      const restDays = Math.floor(days % 30);

      return `Hace ${restDays} día${restDays !== 1 ? "s" : ""}`;
    } else if (hours > 0) {
      const restHours = Math.floor(hours % 24);

      return `Hace ${restHours} hora${restHours !== 1 ? "s" : ""}`;
    } else if (minutes > 0) {
      const restMinutes = Math.floor(minutes % 60);

      return `Hace ${restMinutes} minuto${restMinutes !== 1 ? "s" : ""}`;
    } else {
      return "Hace pocos segundos";
    }
  },
};

const formatString = (message) => {
  return message.replace("el", "").replace("la", "");
};

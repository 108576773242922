import { createRoute } from "./createRouter";

const routes = [
  createRoute("/notallowed", "notAllowed", () => import("@/views/NotAllowed.vue")),
  createRoute("/notaccess", "notAccess", () => import("@/views/NotAcess.vue")),
  createRoute("/sesionwrong", "sesionWrong", () => import("../views/NotSesion.vue")),
  createRoute("/denied", "Denied", () => import("@/common/Denied.vue")),
];

export default routes;

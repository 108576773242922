import Swal from "sweetalert2";

export const alerts = {
  dataSendConfirm: function({ title, message, buttonTextYes, buttonTextNo } = {}) {
    return Swal.fire({
      title: title || "¿Está seguro?",
      text: message || "Sus datos serán enviados",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#bd0909",
      cancelButtonColor: "##515151",
      confirmButtonText: buttonTextYes || "Si, enviar",
      cancelButtonText: buttonTextNo || "No, cancelar",
    });
  },

  dataSending: function({ title, message } = {}) {
    Swal.fire({
      title: title || "Enviando",
      text: message || "Los datos se están enviando y procesando",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  },

  dataSendError: function({ title, message, buttonText } = {}) {
    Swal.close();
    Swal.fire({
      title: title || "Error",
      html: message || "Error enviando los datos",
      icon: "error",
      timerProgressBar: true,
      confirmButtonColor: "#bd0909",
    });
  },

  dataSendSuccess: function({ title, message, buttonText } = {}) {
    Swal.close();
    Swal.fire({
      title: title || "Completado",
      text: message || "Haz enviado tus datos exitosamente, pronto te contactaremos",
      icon: "success",
      confirmButtonText: buttonText || "Aceptar",
      timerProgressBar: true,
      confirmButtonColor: "#bd0909",
    });
  },
  dataInfo: function({ title, message, buttonText } = {}) {
    Swal.close();
    Swal.fire({
      title: title || "",
      text: message || "",
      icon: "info",
      confirmButtonText: buttonText || "Aceptar",
      timerProgressBar: true,
      confirmButtonColor: "#bd0909",
    });
  },
  closeSwal: function() {
    Swal.close();
  },
  endTransmission: function(onDecision) {
    const myOption = `
        <div class="endTransmission">
          <p>Elige una opción</p>
          <table>
            <tr>
              <td><input type="radio" id="break" name="superSelect" value="break"></td>
              <td><label for="break">Receso</label></td>
            </tr>
            <tr>
              <td><input type="radio" id="endTransmission" name="superSelect" value="endTransmission"></td>
              <td padding-left><label for="endTransmission">Finalizar transmisión</label></td>
            </tr>
          </table>
        </div>`;
    Swal.fire({
      title: "Desconexión",
      html: myOption,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#bd0909",
      cancelButtonColor: "#515151",
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      preConfirm: () => {
        const decision = Swal.getPopup().querySelector(".endTransmission input:checked");
        if (!decision) {
          this.dataSendError({ message: "No has tomado ninguna decisión" });
        } else {
          onDecision(decision.value);
        }
      },
    });
  },
  onChangeProgress: function(percentNumber) {
    let percent = document.querySelector(".swal2-popup .percentAlert__number");
    if (!percent) {
      const loader = document.querySelector(".swal2-popup .swal2-loader");
      const container = loader.parentElement;
      container.style = `position: relative;`;
      loader.style = `display:block; width: 3.5em; height: 3.5em; background-color: transparent`;
      percent = document.createElement("p");
      percent.classList.add("percentAlert__number");
      percent.innerText = `${percentNumber}%`;
      percent.style = `margin: 0; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center; letter-spacing: 1.2px; font-weight: bold; font-size: 0.9em; color: #bd0909;`;
      container.appendChild(percent);
    } else {
      percent.innerText = `${percentNumber}%`;
    }
  },
};

export default alerts;
